import * as ACTION_TYPES from '../actions/action_types';

const initialUtilisateurState = {
    token: "",
    obj: {}
};

const UtilisateurReducer = (state = initialUtilisateurState, action) => {
    switch(action.type) {
        case ACTION_TYPES.SAVE_UTILISATEUR_TOKEN:
            return {
                ...state,
                token: action.payload
            };
        case ACTION_TYPES.SAVE_UTILISATEUR_OBJ:
            return {
                ...state,
                obj: action.payload
            };
        case ACTION_TYPES.SAVE_UTILISATEUR_ALL:
            return action.payload;
        case ACTION_TYPES.CLEAR_UTILISATEUR:
            return initialUtilisateurState;
        default:
            return state
    }
};

export default UtilisateurReducer;
