import React, { forwardRef, useEffect, useState } from 'react'
import { 
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    Slide,
    CircularProgress
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRequest, getRequest } from '../../functions/MakeApiCall';
import Notification from './Notification';
import _ from "lodash";
import * as ACTIONS from "../../store/actions/actions";
import { ResponseMessage } from '../../functions/MessageFunctions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    rootEmpty: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh"
    },
    root: {
        display: "flex"
    }
}));

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Notifications() {

    const classes = useStyles();

    const dispatch = useDispatch();
    
    const token = useSelector(state => state.utilisateur.token);

    const [isLoading, setIsLoading] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [notificationID, setNotificationID] = useState(null);
    const [allNotifications, setAllNotifications] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getRequest('notification/getAllNotification', token).then((response) => {
            setAllNotifications(response.data);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            let msg = _.get(err.response, 'data.message', 'Erreur interne');
            dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
        })
    }, [token, dispatch])

    function closeDeleteDialog() {
        setNotificationID(null);
        setDeleteDialogOpen(false);
    }

    function firstDeleteNotification(notifID) {
        setNotificationID(notifID);
        setDeleteDialogOpen(true);
    }

    function deleteNotification() {
        setIsLoading(true);
        deleteRequest('notification/deleteNotification/' + notificationID, token).then(() => {
            let newAllNotifications = [];
            allNotifications.forEach((notification) => {
                if(notification.idNotification !== notificationID) newAllNotifications.push({ ...notification });
            })
            setAllNotifications(newAllNotifications);
            closeDeleteDialog();
            setIsLoading(false);
            dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage("Notification supprimée avec succès.", 'success')));
        }).catch((err) => {
            closeDeleteDialog();
            setIsLoading(false);
            let msg = _.get(err.response, 'data.message', 'Erreur interne');
            dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
        })
    }

    return (
        <div className={isLoading || allNotifications.length === 0? classes.rootEmpty : classes.root}>
            {isLoading?
                <CircularProgress size={36} disableShrink />
                : allNotifications.length === 0?
                <p> Pas de notification ! </p>
                :
                <Grid container justify="center" alignItems="center">
                    {allNotifications.sort((a, b) => b.idClient - a.idClient).map((notification) => (
                        <Grid item key={notification.idNotification} xs={12} style={{ margin: 10 }}>
                            <Notification obj={notification} deleteNotification={() => firstDeleteNotification(notification.idNotification)} />
                        </Grid>
                    ))}
                </Grid>
            }
            <Dialog
                open={deleteDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeDeleteDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Êtes-vous sûr de vouloir supprimer cette notification?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={deleteNotification} color="primary">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
