import React , { useState, useEffect, useCallback } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
    AppBar,
    Tabs,
    Tab,
    Box 
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    InformationGenerale,
    RelationPersonelle,
    EngagementFinancier,
    Impot,
    Assurance,
    ContratTiers,
    VieIndividuelle,
    DemandeModification
} from "../Tabs";
import { useDispatch, useSelector } from 'react-redux';
import * as ACTIONS from "../../store/actions/actions"
import { getListeParametre } from '../../functions/ParamFunctions';
import _ from "lodash";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            style={{height: 500}}
            {...other}
        >
            {value === index && (
                <Box p={5}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    labelTab: {
        textTransform: "none"
    }
}));

export default function VisuClient({ history, ...otherProps }) {

    const dispatch = useDispatch();

    const classes = useStyles();

    const theme = useTheme();

    const clientObj = useSelector(state => state.client.obj);

    const [value, setValue] = useState(0);
    const [allTabs, setAllTabs] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    useEffect(() => {
        getListeParametre([
            "Pays",
            "Titres",
            "Etats Civils",
            "Type Logement",
            "Types Remarque",
            "Types Relation"
        ])
        return () => {
            dispatch(ACTIONS.SAVE_CLIENT("CLEAR"));
            dispatch(ACTIONS.SAVE_PARAM("CLEAR"));
        }
    }, [dispatch]);

    const getAllTabs = useCallback(() => {
        let newAllTabs = [
            {
                label: "Information Générale",
                component: () => <InformationGenerale visu/>
            }
        ];
        if(_.get(clientObj, "setRelation", []).length > 0) {
            newAllTabs.push({
                label: "Relation Personelle",
                component: () => <RelationPersonelle history={history} changeTabValue={handleChangeIndex} visu/>
            })
        }
        if(_.get(clientObj, "setVieIndividuelle", []).length > 0) {
            newAllTabs.push({
                label: "Vie Individuelle",
                component: () => <VieIndividuelle visu/>
            })
        }
        if(_.get(clientObj, "setEngagementFinancier", []).length > 0) {
            newAllTabs.push({
                label: "Engagement Financier",
                component: () => <EngagementFinancier visu/>
            })
        }
        if(_.get(clientObj, "setImpot", []).length > 0) {
            newAllTabs.push({
                label: "Impôt",
                component: () => <Impot visu/>
            })
        }
        if(_.get(clientObj, "setAssurance", []).length > 0) {
            newAllTabs.push({
                label: "Assurance",
                component: () => <Assurance visu/>
            })
        }
        if(_.get(clientObj, "setContratTiers", []).length > 0) {
            newAllTabs.push({
                label: "Contrat Tiers",
                component: () => <ContratTiers visu/>
            })
        }
        newAllTabs.push({
            label: "Demande Modification",
            component: () => <DemandeModification />
        })
        return newAllTabs;
    }, [clientObj, history]);

    useEffect(() => {
        setAllTabs(getAllTabs());
    }, [getAllTabs]);


    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                >
                    {allTabs.map((tab, index) => (
                        <Tab label={tab.label} {...a11yProps(index)} className={classes.labelTab} disabled={!clientObj.idClient}/>
                    ))}
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {allTabs.map((tab, index) => {
                    const TabComponent = tab.component; 
                    return (
                        <TabPanel value={value} index={index} dir={theme.direction}>
                            <TabComponent />
                        </TabPanel>
                    )
                })}
            </SwipeableViews>
        </div>
    );
}