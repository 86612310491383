import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    Typography,
    Grid,
    Button,
    Icon
} from '@material-ui/core';
import AppInput from "../../components/AppInput/AppInput";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    card: {
        height: 200,
        padding: 20,
        cursor: "pointer",
        "&:hover": { 
            backgroundColor: "#F6F6F6"
        }
    },
    nonClickableCard: {
        height: 200,
        padding: 20
    },
    cardInput: {
        height: "80%",
        marginTop: 20
    },
    centerCard: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 20
    }
}));

function isPositiveInteger(value) {
    return /^\d+$/.test(value);
}

export default function CardPage({ handleClick }) {

    const classes = useStyles();
    
    const [age, setAge] = useState(0);
    const [npa, setNpa] = useState("");

    return (
        <div>
            <Grid container justify="space-around" style={{marginBottom: 20}}>
                <Grid item xs={4}>
                    <Card
                        className={clsx(classes.card, classes.centerCard)}
                        variant="outlined"
                        onClick={() => handleClick('moteur/getClientLocataire')}
                    >
                        <Typography className={classes.title}>
                            Tous les clients locataires
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card
                        className={clsx(classes.card, classes.centerCard)}
                        variant="outlined"
                        onClick={() => handleClick('moteur/getClientProprietaire')}
                    >
                        <Typography className={classes.title}>
                            Tous les clients propriétaires
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
            <Grid container justify="space-around">
                <Grid item xs={4}>
                    <Card
                        className={clsx(classes.card, classes.centerCard)}
                        variant="outlined"
                        onClick={() => handleClick('moteur/getClientProspect')}
                    >
                        <Typography className={classes.title}>
                            Tous les prospects
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card
                        className={classes.nonClickableCard}
                        variant="outlined"
                    >
                        <div
                            onClick={() => handleClick('moteur/getClientProprietaire')}
                        >
                            <Typography className={classes.title}>
                                Tous les clients ayant :
                            </Typography>
                        </div>
                        <div
                            className={classes.cardInput}
                        >
                            <Grid container justify="space-around">
                                <Grid item xs={5}>
                                    <AppInput
                                        label="Âge supérieur à"
                                        value={age}
                                        onChange={(ev) => {
                                            let number = ev.target.value;
                                            if(isPositiveInteger(number)) parseInt(number);
                                            else if(!number) number = 0;
                                            else return;
                                            setAge(number);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <AppInput
                                        label="NPA"
                                        value={npa}
                                        onChange={(ev) => {
                                            setNpa(ev.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item xs={4}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ textTransform: "none", marginTop: 20 }}
                                        endIcon={<Icon> chevron_right </Icon>}
                                        onClick={() => {
                                            let endpoint = 'moteur/getClientAgeNpa/' + age;
                                            if(npa) endpoint += '/' + npa; 
                                            handleClick(endpoint);
                                        }}
                                    >
                                        Suivant
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
