import React from "react";
import { CircularProgress } from "@material-ui/core";

export default function TableLoading() {
    return (
        <div style={{
            display: "table",
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)"
        }}>
            <div style={{
                display: "table-cell",
                width: "100%",
                height: "100%",
                verticalAlign: "middle",
                textAlign: "center"
            }}>
                <CircularProgress size={40} disableShrink/>
            </div>
        </div>
    )
}