import React from "react";
import {
    Card,
    CardContent
} from '@material-ui/core';

export default function AuthLayout({children}) {
	return (
		<div>
			<div style={{
				display: "flex",
				padding: "80px",
				justifyContent: "center"
			}}>
				<Card>
					<CardContent>
					{children}
					</CardContent>
				</Card>
			</div>
		</div>
	)
}