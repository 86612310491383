import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ACTIONS from "../../store/actions/actions"
import { deleteRequest, getRequest } from '../../functions/MakeApiCall';
import ClientTable from '../../components/ClientTable/ClientTable';
import { ResponseMessage } from '../../functions/MessageFunctions';
import _ from "lodash";

export default function RechercheClient({ history, ...otherProps }) {
    
    const dispatch = useDispatch();

    const token = useSelector(state => state.utilisateur.token);

    const [isLoading, setIsLoading] = useState(false);
    const [allClients, setAllClients] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getRequest('client/getAllClient', token).then((response) => {
            setAllClients(response.data);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            let msg = _.get(err.response, 'data.message', 'Erreur interne');
            dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
        })
    }, [token, dispatch])

    function onRowDelete(oldData) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let newAllClients = [ ...allClients ];
                newAllClients.splice(newAllClients.indexOf(oldData), 1);

                deleteRequest('client/deleteClient/' + oldData.idClient, token).then(() => {
                    setAllClients(newAllClients);
                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Suppression du client " + oldData.prenom + " réussie.", "success")));
                    resolve();
                }).catch((err) => {
                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                    reject();
                })
            }, 600);
        })
    }

    return (
        <div style={{padding: 16}}>
            <ClientTable
                clients={allClients}
                onRowDelete={onRowDelete}
                isLoading={isLoading}
                history={history}
            />
        </div>
    )
}
