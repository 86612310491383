export function ResponseMessage(message='',variant=null,autoHideDuration=3000){
    return {
            message     : message,//text or html
            autoHideDuration: autoHideDuration,//ms
            anchorOrigin: {
                vertical  : 'bottom',//top bottom
                horizontal: 'right'//left center right
            },
            variant: variant//success error info warning null
	};
}
