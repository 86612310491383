import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import routes from "./routes";

import SnackbarMessage from "./components/SnackbarMessage/SnackbarMessage";
import { useSelector } from "react-redux";
import { isAdmin } from "./functions/UtilisateurFunctions";


export default function App(props) {

    const token = useSelector(state => state.utilisateur.token);
    const utilisateurObj = useSelector(state => state.utilisateur.obj);

    const loggedIn = token? true : false;
    const admin = isAdmin(utilisateurObj);

    return (
        <>
            <Router basename={process.env.REACT_APP_BASENAME || ""}>
                <Switch>
                    {routes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={(props) => {
                                    if ((admin || (!admin && !route.admin)) && (loggedIn || (!loggedIn && !route.login))) {
                                        return (
                                            <route.layout {...props}>
                                                <route.component {...props} />
                                            </route.layout>
                                        );
                                    } else {
                                        return (
                                            <Redirect to="/login"/>
                                        )
                                    };
                                }}
                            />
                        );
                    })}
                </Switch>
            </Router>
            <SnackbarMessage/>
        </>
    );
}
