import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ClientTable from '../../components/ClientTable/ClientTable'
import * as ACTIONS from "../../store/actions/actions"
import { deleteRequest, getRequest } from '../../functions/MakeApiCall';
import { ResponseMessage } from '../../functions/MessageFunctions';
import _ from "lodash";
import { Button, Icon } from '@material-ui/core';

export default function ClientPage({ history, endpoint, handleBack }) {
    
    const dispatch = useDispatch();

    const token = useSelector(state => state.utilisateur.token);

    const [clients, setClients] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        if(!endpoint) return;
        setIsLoading(true);
        getRequest(endpoint, token).then((response) => {
            setClients(response.data);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            let msg = _.get(err.response, 'data.message', 'Erreur interne');
            dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
        })
    }, [dispatch, endpoint, token]);

    

    function onRowDelete(oldData) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let newClients = [ ...clients ];
                newClients.splice(newClients.indexOf(oldData), 1);

                deleteRequest('client/deleteClient/' + oldData.idClient, token).then(() => {
                    setClients(newClients);
                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Suppression du client " + oldData.prenom + " réussie.", "success")));
                    resolve();
                }).catch((err) => {
                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                    reject();
                })
            }, 600);
        })
    }

    return (
        <div style={{padding: 16}}>
            <ClientTable
                clients={clients}
                onRowDelete={onRowDelete}
                isLoading={isLoading}
                history={history}
            />
            <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "none", marginTop: 20 }}
                startIcon={<Icon> chevron_left </Icon>}
                onClick={handleBack}
            >
                Retour
            </Button>
        </div>
    )
}
