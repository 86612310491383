import { postRequest } from "./MakeApiCall";
import { store } from "../store/index";
import * as ACTIONS from "../store/actions/actions";

export async function getListeParametre(allNom) {
    const storeState = store.getState();
    const token = storeState.utilisateur.token;

    postRequest('parametre/multipleListeParametre', allNom, token).then((response) => {
        let returnedLists = response.data;
        let allLists = {};
        Object.keys(returnedLists).forEach((key) => {
            let allWords = key.split(" ");
            allWords.unshift("liste");
            allLists[allWords.join("")] = returnedLists[key];
        })
        
        store.dispatch(ACTIONS.SAVE_PARAM("ALL", {
            initialized: true,
            lists: allLists
        }))
    }); 
}

export function getValueFromList(listParametre, idParametre, param = true) {
    let label = "label";
    let value = "value";
    if(param) {
        label = "valeur";
        value = "idParametre"
    }
    let parametre = (listParametre || []).find((p) => p[value] === idParametre);
    if(!parametre) return "";
    return parametre[label];
}