import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable, { MTableToolbar } from 'material-table'
import _ from "lodash";
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import { Icon, Tooltip, Typography } from '@material-ui/core';
import { getColumnTitle } from '../../../functions/TableFunctions';
import { getValueFromList } from '../../../functions/ParamFunctions';
import { getRequest, postRequest } from '../../../functions/MakeApiCall';
import * as ACTIONS from "../../../store/actions/actions";
import { ResponseMessage } from '../../../functions/MessageFunctions';
import ParametreSelect from '../../../components/ParametreSelect/ParametreSelect';
import Select from "react-select";
import TableLoading from '../../../components/TableLoading/TableLoading';

const useStyles = makeStyles((theme) => ({
    formGroup: {
        position: "relative",
        border: "1px solid " + theme.palette.divider,
        borderRadius: 2,
        padding: "12px 12px 0 12px",
    },
    formGroupTitle: {
        position: "absolute",
        top: -10,
        left: 8,
        fontWeight: 600,
        padding: "0 4px",
        backgroundColor: theme.palette.background.paper,
    }
}));

export default function RelationPersonelle({ history, changeTabValue, visu, ...otherProps }) {
    
    const classes = useStyles();

    const addButtonRef = useRef();

    const dispatch = useDispatch();
    
    const {
        listeTypesRelation
    } = useSelector(state => state.param.lists)

    const token = useSelector(state => state.utilisateur.token);
    const clientObj = useSelector(state => state.client.obj);

    const [tableColumns, setTableColumns] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);

    useEffect(() => {
        setTableColumns([
            {
                title: getColumnTitle("Type Relation"),
                field: "paramTypeRelation.idParametre",
                render: (rowData) => (
                    <span>{getValueFromList(listeTypesRelation, _.get(rowData, 'paramTypeRelation.idParametre', ''))}</span>
                ),
                editComponent: (props) => (
                    <ParametreSelect
                        value={_.get(props.rowData, 'paramTypeRelation.idParametre', '')}
                        options={listeTypesRelation}
                        onChange={(selected) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                paramTypeRelation: {
                                    idParametre: selected.idParametre
                                }
                            })
                        }}
                    />
                )
            },
            {
                title: getColumnTitle("Client relatif"),
                field: "idClientRelatif",
                render: (rowData) => (
                    <span>{getValueFromList(clientOptions, _.get(rowData, 'idClientRelatif', ''), false)}</span>
                ),
                editComponent: (props) => (
                    <Select
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        options={clientOptions}
                        value={clientOptions.find(option => option.value === _.get(props.rowData, 'idClientRelatif', '')) || null}
                        onChange={(selected) => {
                            props.onChange(selected.value);
                        }}
                    />
                )
            }
        ])
    }, [listeTypesRelation, clientOptions])

    useEffect(() => {
        getRequest('client/getAllClient', token).then((response) => {
            let returnedClients = response.data;
            let newClientOptions = [];
            returnedClients.forEach((client) => {
                if(client.idClient !== clientObj.idClient) {
                    newClientOptions.push({
                        label: getClientLabel(client),
                        value: client.idClient,
                        clientObj: { ...client }
                    })
                }
            })
            setClientOptions(newClientOptions);
        })
    }, [clientObj.idClient, token])

    function getClientLabel(client) {
        let allLabel = [];

        let title = _.get(client, "paramTitre.valeur", "");
        if(title) allLabel.push(title);
        if(client.prenom) allLabel.push(client.prenom);
        if(client.nom) allLabel.push(client.nom);

        return allLabel.join(" ");
    }

    return (
        <div className={clsx(classes.formGroup, "px-12 py-12")}>
            {!visu && (
                <Typography
                    className={classes.formGroupTitle}
                    color="textSecondary"
                >
                    <span style={{ display: "flex" }}>
                        <span style={{ paddingLeft: 4, display: "flex" }}>
                            <Tooltip title="Ajouter" placement="top-start">
                                <Icon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        addButtonRef.current.click();
                                    }}
                                >
                                        add_box
                                </Icon>   
                            </Tooltip>
                        </span>
                    </span>
                </Typography>
            )}
            <MaterialTable
                options={{
                    actionsColumnIndex: tableColumns.length,
                    addRowPosition: "first",
                    draggable: false
                }}
                components={{
                    OverlayLoading: TableLoading,
                    Toolbar: (props) => (
                        <div style={{ display: "none", height: "0" }}>
                            <MTableToolbar {...props} />
                        </div>
                    )
                }}
                columns={tableColumns}
                data={[ ...clientObj.setRelation || [] ].sort((a, b) => b.idRelation - a.idRelation)}
                icons={{
                    Add: (props) => <div ref={addButtonRef} />,
                }}
                editable={{
                    onRowAdd: visu? undefined : (newData) => visu? undefined :
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if(!_.get(newData, "paramTypeRelation.idParametre", "")
                                    || !_.get(newData, "idClientRelatif", ""))
                                {
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Fields obligatoires manquants !", "error")));
                                    return reject();
                                }

                                let newTableData = [ ..._.get(clientObj, 'setRelation', []) || [] ];
                                newTableData.push(newData);

                                let body = {
                                    ...clientObj,
                                    setRelation: [ ...newTableData ]
                                };
                                
                                postRequest('client/saveClient', body, token).then((response) => {
                                    dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Relation créée avec succès.", "success")));
                                    resolve();
                                }).catch((err) => {
                                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                    return reject();
                                })
                            }, 600);
                        }),
                    onRowUpdate: visu? undefined : (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if(!_.get(newData, "paramTypeRelation.idParametre", "")
                                || !_.get(newData, "idClientRelatif", ""))
                                {
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Fields obligatoires manquants !", "error")));
                                    return reject();
                                }

                                let newTableData = [ ..._.get(clientObj, 'setRelation', []) || [] ];
                                newTableData[newTableData.indexOf(oldData)] = newData;

                                let body = {
                                    ...clientObj,
                                    setRelation: [ ...newTableData ]
                                };
                                
                                postRequest('client/saveClient', body, token).then((response) => {
                                    dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Relation modifiée avec succès.", "success")));
                                    resolve();
                                }).catch((err) => {
                                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                    return reject();
                                })
                            }, 600);
                        }),
                    onRowDelete: visu? undefined : (oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                let newTableData = [ ..._.get(clientObj, 'setRelation', []) || [] ];
                                newTableData.splice(newTableData.indexOf(oldData), 1);
                                
                                let body = {
                                    ...clientObj,
                                    setRelation: [ ...newTableData ]
                                };
                                
                                postRequest('client/saveClient', body, token).then((response) => {
                                    dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Relation supprimée avec succès.", "success")));
                                    resolve();
                                }).catch((err) => {
                                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                    return reject();
                                })
                            }, 600);
                        })
                }}
                localization={{
                    header: {
                        actions: ""
                    },
                    body: {
                        emptyDataSourceMessage: "Pas de relations !",
                        editRow: {
                            deleteText: "Êtes-vous sure de vouloir supprimer cette relation ?",
                        },
                        deleteTooltip: "Supprimer",
                        editTooltip: "Modifier"
                    },
                }}
                onRowClick={visu? undefined : (event, rowData) => {
                    let clientOption = clientOptions.find((option) => option.value === rowData.idClientRelatif);
                    dispatch(ACTIONS.SAVE_CLIENT("OBJ", clientOption.clientObj));
                    changeTabValue(0);
                    history.push("/AjouterClient");
                }}
            />
        </div>
    )
}
