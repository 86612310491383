import { postRequest } from "./MakeApiCall";
import { store } from "../store/index";

export async function documentUpload(document) {
    const storeState = store.getState();
    const token = storeState.utilisateur.token;

    let formData = new FormData();
    formData.append("file", document);
    return postRequest('document/upload', formData, token)
}