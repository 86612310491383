import React, { useRef, useState, useEffect, forwardRef } from 'react'
import { documentUpload } from "../../../functions/DocumentFunctions";
import _ from "lodash";
import * as ACTIONS from "../../../store/actions/actions";
import { useDispatch, useSelector } from 'react-redux';
import { ResponseMessage } from '../../../functions/MessageFunctions';
import MaterialTable,  { MTableToolbar } from 'material-table';
import TableLoading from '../../../components/TableLoading/TableLoading';
import AppDatePicker from '../../../components/AppDatePicker/AppDatePicker';
import { makeStyles } from '@material-ui/core/styles';
import { postRequest } from '../../../functions/MakeApiCall';
import clsx from "clsx";
import {
    Dialog,
    CircularProgress,
    Icon,
    IconButton,
    Slide,
    Tooltip,
    Typography
} from '@material-ui/core';
import { getColumnTitle, isEmptyRow } from '../../../functions/TableFunctions';
import { FILE_IP } from "../../../constants/FILE_IP";
import AppInput from '../../../components/AppInput/AppInput';

const useStyles = makeStyles((theme) => ({
    formGroup: {
        position: "relative",
        border: "1px solid " + theme.palette.divider,
        borderRadius: 2,
        padding: "12px 12px 0 12px",
    },
    formGroupTitle: {
        position: "absolute",
        top: -10,
        left: 8,
        fontWeight: 600,
        padding: "0 4px",
        backgroundColor: theme.palette.background.paper,
    }
}));

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Assurance({ visu }) {
    
    const classes = useStyles();

    const inputFileRef = useRef();
    const addButtonRef = useRef();
    const addDocumentButtonRef = useRef();

    const dispatch = useDispatch();

    const token = useSelector(state => state.utilisateur.token);
    const clientObj = useSelector(state => state.client.obj);

    const [uploading, setUploading] = useState(false);
    const [documentPath, setDocumentPath] = useState("");
    
    const [tableColumns, setTableColumns] = useState([]);
    const [documentTableColumns, setDocumentTableColumns] = useState([]);

    const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
    const [assuranceID, setAssuranceID] = useState(null);

    useEffect(() => {
        setTableColumns([
            {
                title: getColumnTitle("Numéro Contrat"),
                field: "objInformationContrat.numeroContrat",
                editComponent: (props) => (
                    <AppInput
                        value={_.get(props.rowData, "objInformationContrat.numeroContrat", "")}
                        onChange={(ev) => {
                            props.onChange(ev.target.value)
                        }}
                    />
                ),
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            },
            {
                title: getColumnTitle("Companie"),
                field: "objInformationContrat.companie",
                editComponent: (props) => (
                    <AppInput
                        value={_.get(props.rowData, "objInformationContrat.companie", "")}
                        onChange={(ev) => {
                            props.onChange(ev.target.value)
                        }}
                    />
                ),
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            },
            {
                title: getColumnTitle("Remarque"),
                field: "objInformationContrat.remarque",
                editComponent: (props) => (
                    <AppInput
                        value={_.get(props.rowData, "objInformationContrat.remarque", "")}
                        onChange={(ev) => {
                            props.onChange(ev.target.value)
                        }}
                    />
                ),
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            },
            {
                title: getColumnTitle("Date début"),
                field: "objInformationContrat.dateDebut",
                editComponent: (props) => (
                    <AppDatePicker
                        value={_.get(props.rowData, "objInformationContrat.dateDebut", "")}
                        onChange={(date) => {
                            props.onChange(date);
                        }}
                    />
                ),
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            },
            {
                title: getColumnTitle("Date fin"),
                field: "objInformationContrat.dateFin",
                editComponent: (props) => (
                    <AppDatePicker
                        value={_.get(props.rowData, "objInformationContrat.dateFin", "")}
                        onChange={(date) => {
                            props.onChange(date);
                        }}
                    />
                ),
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            },
            {
                title: getColumnTitle("Prime"),
                field: "objInformationContrat.prime",
                editComponent: (props) => (
                    <AppInput
                        value={_.get(props.rowData, "objInformationContrat.prime", "")}
                        onChange={(ev) => {
                            props.onChange(ev.target.value)
                        }}
                    />
                ),
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            },
            {
                title: getColumnTitle("Documents"),
                field: "objInformationContrat.setDocument",
                render: (rowData) => (
                    <IconButton
                        onClick={() => {
                            setAssuranceID(rowData.idAssurance);
                            setDocumentDialogOpen(documentDialogOpen => !documentDialogOpen)
                        }}
                        disabled={!rowData.idAssurance || documentDialogOpen}
                    >
                        <Icon>
                            folder
                        </Icon>
                    </IconButton>
                ),
                editComponent: (rowData) => null,
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            }
        ]);
    }, [documentDialogOpen])

    useEffect(() => {
        setDocumentTableColumns([
            {
                title: getColumnTitle("Référence"),
                field: "reference",
                editComponent: (props) => (
                    <AppInput
                        value={_.get(props.rowData, 'reference', '')}
                        onChange={(ev) => {
                            props.onChange(ev.target.value)
                        }}
                    />
                ),
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            },
            {
                title: getColumnTitle("Document"),
                field: "path",
                render: (rowData) => (
                    <IconButton 
                        onClick={() => {
                            let fullPath = FILE_IP + _.get(rowData, "path", "");
                            window.open(fullPath, "_blank");
                        }}
                        disabled={!_.get(rowData, "path", "")}
                    >
                        <Icon>
                            visibility
                        </Icon>
                    </IconButton>
                ),
                editComponent: (props) => (
                    uploading? 
                        <CircularProgress size={24} disableShrink />
                        :
                        <IconButton
                            onClick={() => {
                                inputFileRef.current.click();
                            }}
                        >
                            <Icon>
                                {!_.get(props.rowData, "path", "") && !documentPath?
                                    "cloud_upload"
                                    :
                                    "insert_drive_file"
                                }
                            </Icon>
                        </IconButton>
                ),
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            }
        ]);
    }, [uploading, inputFileRef, documentPath])

    function selectDocument(ev)
    {
        ev.preventDefault();
        let allFiles = _.get(ev, "target.files", []);
        if (allFiles.length > 0) {
            setUploading(true);
            documentUpload(ev.target.files[0]).then((response) => {
                setUploading(false);
                setDocumentPath(response.data.path);
                dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Upload du document réussi avec succès.", "success")))
            }).catch((err) => {
                setUploading(false);
                let msg = _.get(err.response, 'data.message', 'Erreur interne');
                dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
            })
        }
    }

    function getSetDocument() {
        let assurance = [ ...clientObj.setAssurance || [] ].find((assurance) => assurance.idAssurance === assuranceID);
        return [ ..._.get(assurance, "objInformationContrat.setDocument", []) || [] ].sort((a, b) => b.idDocument - a.idDocument);
    }

    return (
        <div className={clsx(classes.formGroup, "px-12 py-12")}>
            {!visu && (
                <Typography
                    className={classes.formGroupTitle}
                    color="textSecondary"
                >
                    <span style={{ display: "flex" }}>
                        <span style={{ paddingLeft: 4, display: "flex" }}>
                            <Tooltip title="Ajouter" placement="top-start">
                                <Icon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        addButtonRef.current.click();
                                    }}
                                >
                                        add_box
                                </Icon>   
                            </Tooltip>
                        </span>
                    </span>
                </Typography>
            )}
            <MaterialTable
                options={{
                    actionsColumnIndex: tableColumns.length,
                    addRowPosition: "first",
                    draggable: false
                }}
                components={{
                    OverlayLoading: TableLoading,
                    Toolbar: (props) => (
                        <div style={{ display: "none", height: "0" }}>
                            <MTableToolbar {...props} />
                        </div>
                    )
                }}
                columns={tableColumns}
                data={[ ...clientObj.setAssurance || [] ].sort((a, b) => b.idAssurance - a.idAssurance)}
                icons={{
                    Add: (props) => <div ref={addButtonRef} />,
                }}
                editable={{
                    onRowAdd: visu? undefined : (newData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if(isEmptyRow(newData))
                                {
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Fields obligatoires manquants !", "error")));
                                    return reject();
                                }

                                let newTableData = [ ..._.get(clientObj, 'setAssurance', []) || [] ];
                                newTableData.push(newData);

                                let body = {
                                    ...clientObj,
                                    setAssurance: [ ...newTableData ]
                                };
                                
                                postRequest('client/saveClient', body, token).then((response) => {
                                    dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Assurance créée avec succès.", "success")));
                                    resolve();
                                }).catch((err) => {
                                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                    return reject();
                                })
                            }, 600);
                        }),
                    onRowUpdate: visu? undefined : (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if(isEmptyRow(newData))
                                {
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Fields obligatoires manquants !", "error")));
                                    return reject();
                                }

                                let newTableData = [ ..._.get(clientObj, 'setAssurance', []) || [] ];
                                newTableData[newTableData.indexOf(oldData)] = newData;

                                let body = {
                                    ...clientObj,
                                    setAssurance: [ ...newTableData ]
                                };
                                
                                postRequest('client/saveClient', body, token).then((response) => {
                                    dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Assurance modifiée avec succès.", "success")));
                                    resolve();
                                }).catch((err) => {
                                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                    return reject();
                                })
                            }, 600);
                        }),
                    onRowDelete: visu? undefined : (oldData) => 
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                let newTableData = [ ..._.get(clientObj, 'setAssurance', []) || [] ];
                                newTableData.splice(newTableData.indexOf(oldData), 1);
                                
                                let body = {
                                    ...clientObj,
                                    setAssurance: [ ...newTableData ]
                                };
                                
                                postRequest('client/saveClient', body, token).then((response) => {
                                    dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Assurance supprimée avec succès.", "success")));
                                    resolve();
                                }).catch((err) => {
                                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                    return reject();
                                })
                            }, 600);
                        })
                }}
                localization={{
                    header: {
                        actions: ""
                    },
                    body: {
                        emptyDataSourceMessage: "Pas d'assurance !",
                        editRow: {
                            deleteText: "Êtes-vous sure de vouloir supprimer cette assurance ?",
                        },
                        deleteTooltip: "Supprimer",
                        editTooltip: "Modifier"
                    },
                }}
            />
            <Dialog
                open={documentDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth="sm"
                onClose={() => setDocumentDialogOpen(false)}
            >
                <div style={{ padding: 40 }}>
                    <div className={clsx(classes.formGroup, "px-12 py-12")}>
                        {!visu && (
                            <Typography
                                className={classes.formGroupTitle}
                                color="textSecondary"
                            >
                                <span style={{ display: "flex" }}>
                                    <span style={{ paddingLeft: 4, display: "flex" }}>
                                        <Tooltip title="Ajouter" placement="top-start">
                                            <Icon
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    addDocumentButtonRef.current.click();
                                                }}
                                            >
                                                    add_box
                                            </Icon>   
                                        </Tooltip>
                                    </span>
                                </span>
                            </Typography>
                        )}
                        <MaterialTable
                            options={{
                                actionsColumnIndex: tableColumns.length,
                                addRowPosition: "first",
                                draggable: false
                            }}
                            components={{
                                OverlayLoading: TableLoading,
                                Toolbar: (props) => (
                                    <div style={{ display: "none", height: "0" }}>
                                        <MTableToolbar {...props} />
                                    </div>
                                )
                            }}
                            columns={documentTableColumns}
                            data={getSetDocument()}
                            icons={{
                                Add: (props) => <div ref={addDocumentButtonRef} />,
                            }}
                            editable={{
                                onRowAdd: visu? undefined : (newData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            if(documentPath) newData.path = documentPath;

                                            if(isEmptyRow(newData))
                                            {
                                                dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Fields obligatoires manquants !", "error")));
                                                return reject();
                                            }
                                            setDocumentPath("");

                                            let newTableData = getSetDocument();
                                            newTableData.push(newData);

                                            let newSetAssurance = [];
                                            [ ..._.get(clientObj, "setAssurance", []) || [] ].forEach((assurance) => {
                                                let newAssurance = { ...assurance };
                                                if(assurance.idAssurance === assuranceID) {
                                                    newAssurance.objInformationContrat = {
                                                        ...newAssurance.objInformationContrat,
                                                        setDocument: [ ...newTableData ]
                                                    };
                                                }
                                                newSetAssurance.push(newAssurance);
                                            });

                                            let body = {
                                                ...clientObj,
                                                setAssurance: [ ...newSetAssurance ]
                                            };
                                            
                                            postRequest('client/saveClient', body, token).then((response) => {
                                                dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                                dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Document créé avec succès.", "success")));
                                                resolve();
                                            }).catch((err) => {
                                                let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                                return reject();
                                            })
                                        }, 600);
                                    }),
                                onRowUpdate: visu? undefined : (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            if(documentPath) newData.path = documentPath;

                                            if(isEmptyRow(newData))
                                            {
                                                dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Fields obligatoires manquants !", "error")));
                                                return reject();
                                            }
                                            setDocumentPath("");

                                            let newTableData = getSetDocument();
                                            newTableData[newTableData.indexOf(oldData)] = newData;

                                            let newSetAssurance = [];
                                            [ ..._.get(clientObj, "setAssurance", []) || [] ].forEach((assurance) => {
                                                let newAssurance = { ...assurance };
                                                if(assurance.idAssurance === assuranceID) {
                                                    newAssurance.objInformationContrat = {
                                                        ...newAssurance.objInformationContrat,
                                                        setDocument: [ ...newTableData ]
                                                    };
                                                }
                                                newSetAssurance.push(newAssurance);
                                            });

                                            let body = {
                                                ...clientObj,
                                                setAssurance: [ ...newSetAssurance ]
                                            };
                                            
                                            postRequest('client/saveClient', body, token).then((response) => {
                                                dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                                dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Document modifié avec succès.", "success")));
                                                resolve();
                                            }).catch((err) => {
                                                let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                                return reject();
                                            })
                                        }, 600);
                                    }),
                                onRowDelete: visu? undefined : (oldData) => 
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            let newTableData = getSetDocument();
                                            newTableData.splice(newTableData.indexOf(oldData), 1);
                                            
                                            let newSetAssurance = [];
                                            [ ..._.get(clientObj, "setAssurance", []) || [] ].forEach((assurance) => {
                                                let newAssurance = { ...assurance };
                                                if(assurance.idAssurance === assuranceID) {
                                                    newAssurance.objInformationContrat = {
                                                        ...newAssurance.objInformationContrat,
                                                        setDocument: [ ...newTableData ]
                                                    };
                                                }
                                                newSetAssurance.push(newAssurance);
                                            });

                                            let body = {
                                                ...clientObj,
                                                setAssurance: [ ...newSetAssurance ]
                                            };
                                            
                                            postRequest('client/saveClient', body, token).then((response) => {
                                                dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                                dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Document supprimé avec succès.", "success")));
                                                resolve();
                                            }).catch((err) => {
                                                let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                                return reject();
                                            })
                                        }, 600);
                                    })
                            }}
                            localization={{
                                header: {
                                    actions: ""
                                },
                                body: {
                                    emptyDataSourceMessage: "Pas de document !",
                                    editRow: {
                                        deleteText: "Êtes-vous sure de vouloir supprimer cet document ?",
                                    },
                                    deleteTooltip: "Supprimer",
                                    editTooltip: "Modifier"
                                },
                            }}
                        />
                    </div>
                </div>
            </Dialog>
            <input
                type="file"
                style={{display: "none"}}
                ref={inputFileRef}
                onChange={selectDocument}
            /> 
        </div>
    )
}
