import React from 'react'
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { generateId } from '../../functions/UtilFunctions';

const useStyles = makeStyles((theme) => ({
    input: {
        height: 38
    }
}));

export default function AppInput({label, value, onChange, ...otherProps}) {

    const classes = useStyles();

    return (
        <TextField
            id={"outlined-textfield-" + (label || generateId(6))}
            variant="outlined"
            label={label}
            value={value || ""}
            onChange={onChange}
            InputProps={{
                className: classes.input
            }}
            InputLabelProps={{
                shrink: true
            }}
            fullWidth
            {...otherProps}
        />
    );
}