import React, { useState } from 'react';
import {
    TextField,
    Grid,
    Divider,
    CircularProgress,
    InputAdornment,
    IconButton,
    Button
} from "@material-ui/core";
import {
    Visibility,
    VisibilityOff
} from "@material-ui/icons";
import { postRequest } from "../../functions/MakeApiCall";
import { ResponseMessage } from "../../functions/MessageFunctions";
import * as ACTIONS from '../../store/actions/actions';
import { useDispatch } from "react-redux";
import _ from 'lodash';
import { isAdmin } from '../../functions/UtilisateurFunctions';

function Login(props)
{
    const dispatch = useDispatch();

    const [allValues, setAllValues] = useState({
        login : '',
        password : '',
    });

    const [showFields, setShowFields] = useState({
        password : false
    });

    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let missingFields = {
        login : !allValues.login,
        password : !allValues.password
    };

    let largeFields = {
        login : _.get(allValues, 'login', 0).length > 250,
        password : _.get(allValues, 'password', 0).length > 250
    };

    let errorMessages = {
        missingFields : 'Champs obligatoire.',
        largeFields : 'Trop long. (max: 250)'
    };

    function handleKeyPress(ev) {
        let validSubmission = checkIfValid();
        if (ev.key === "Enter" && validSubmission)
        {
            login();
        }
    }

    function getErrorStatus(field)
    {
        return submitted && (missingFields[field] || largeFields[field]);
    }

    function getHelperText(field)
    {
        if(!submitted && (missingFields[field] || largeFields[field])) return '';
        if(missingFields[field])
        {
            return errorMessages['missingFields'];
        } else if(largeFields[field])
        {
            return errorMessages['largeFields'];
        }
    }

    function checkIfValid()
    {
        let valid = true;
        valid = !Object.keys(missingFields).some((key) => missingFields[key]);
        if(valid) valid = !Object.keys(largeFields).some((key) => largeFields[key]);
        return valid;
    }

    function login()
    {
        setIsLoading(true);
        setSubmitted(!submitted);
        let validSubmission = checkIfValid();
        if(!validSubmission)
        {
            setIsLoading(false);
            return;
        }

        postRequest('auth/login', allValues).then((response) => {
            let allData = response.data;
            let token = allData.token;
            let utilisateur = allData.utilisateur;
            dispatch(ACTIONS.SAVE_UTILISATEUR('ALL', {
                token: token,
                obj: utilisateur
            }));
            let newUrl;
            if(isAdmin(utilisateur)) {
                newUrl = "/Notifications";
            } else {
                newUrl = "/VisuClient";
                dispatch(ACTIONS.SAVE_CLIENT('OBJ', { ..._.get(utilisateur, "objClient", {}) }));
            }
            props.history.push(newUrl);
        })
        .catch((e) => {
            let msg = _.get(e.response, 'data.message', 'Erreur interne');
            dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
            setIsLoading(false);
            setSubmitted(false);
        });
    }

    return (
        <div style={{padding: 16}}>
            <p style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.6)"
            }}> Se connecter </p>
            <Divider />
            <Grid
                container
                direction="row"
                justify="space-between"
                spacing={5}
                style={{marginTop: 10}}
            >
                <Grid item xs={12}>
                    <TextField
                        required
                        error={getErrorStatus('login')}
                        helperText={getHelperText('login')}
                        id="outlined-login"
                        label="Login"
                        value={allValues.login}
                        onChange={(ev) => {
                            setAllValues({...allValues,
                                login : ev.target.value
                            });
                        }}
                        onKeyPress={(ev) => handleKeyPress(ev)}
                        variant="outlined"
                        style={{width: '100%'}}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justify="space-between"
                spacing={5}
                style={{marginTop: 10}}
            >
                <Grid item xs={12}>
                    <TextField
                        required
                        error={getErrorStatus('password')}
                        helperText={getHelperText('password')}
                        type={showFields.password ? 'text' : 'password'}
                        id="outlined-password"
                        label="Mot de passe"
                        value={allValues.password}
                        onChange={(ev) => {
                            setAllValues({...allValues,
                                password : ev.target.value
                            });
                        }}
                        onKeyPress={(ev) => handleKeyPress(ev)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            setShowFields({...showFields,
                                                password : !showFields.password
                                            });
                                        }}
                                        onMouseDown={(ev) => {ev.preventDefault()}}
                                        edge="end"
                                    >
                                        {showFields.password ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                        variant="outlined"
                        style={{width: '100%'}}
                    />
                </Grid>
            </Grid>
            <div style={{
                textAlign: 'center',
                marginTop: '30px'
            }}>
                <Button theme="light"
                        variant="contained"
                        disabled={isLoading}
                        onClick={login}
                        style={{textTransform: 'none', background: 'rgba(0, 0, 0, 0.08)'}}>
                    {isLoading?
                        <CircularProgress size={24} disableShrink />
                        :
                        "Se connecter"
                    }
                </Button>
                <div style={{marginTop: '20px', textAlign: '-webkit-center'}}>
                    <Button color="primary" onClick={() => {props.history.push("/signup")}} style={{width: 'fit-content', textTransform: 'none'}}>
                        Nouvel utilisateur? S'inscrire
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Login;
