import React from 'react'
import {
    Grid,
    IconButton,
    Icon,
    Card
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        display: "flex",
        borderRadius: 20,
        padding: 20
    }
}));

export default function Notification({ obj, deleteNotification }) {
    
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <Grid container justify="space-beween" alignItems="center">
                <Grid item xs={11}>
                    {obj.message || ""}
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={deleteNotification}>
                        <Icon> delete </Icon>
                    </IconButton>
                </Grid>
            </Grid>
        </Card>
    )
}
