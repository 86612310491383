import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ParametreSelect from '../../../components/ParametreSelect/ParametreSelect';
import AppInput from '../../../components/AppInput/AppInput';
import AppDatePicker from '../../../components/AppDatePicker/AppDatePicker';
import _ from "lodash";
import { Button, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { ResponseMessage } from "../../../functions/MessageFunctions";
import * as ACTIONS from "../../../store/actions/actions";
import { postRequest } from '../../../functions/MakeApiCall';

export default function InformationGenerale({ visu }) {

    const dispatch = useDispatch();
    
    const {
        listePays,
        listeTitres,
        listeEtatsCivils,
        listeTypeLogement
    } = useSelector(state => state.param.lists);

    const token = useSelector(state => state.utilisateur.token);
    const clientObj = useSelector(state => state.client.obj);

    const [isLoading, setIsLoading] = useState(false);
    const [allValues, setAllValues] = useState({ ...clientObj});

    useEffect(() => {
        setAllValues({ ...clientObj });
    }, [clientObj])

    function save() {
        setIsLoading(true);
        postRequest('client/saveClient', allValues, token).then((response) => {
            let newClient = { ...response.data };
            dispatch(ACTIONS.SAVE_CLIENT("OBJ", newClient));
            dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Client " + newClient.prenom + " sauvegardé avec succès.", "success")));
            setIsLoading(false); 
        }).catch((err) => {
            let msg = _.get(err.response, 'data.message', 'Erreur interne');
            dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
            setIsLoading(false);
        })
    }

    function isValidForm() {
        let booleanFields = ["mandatDeGestion", "prospect"];
        return Object.keys(allValues).some((key) => !booleanFields.includes(key) && allValues[key]);
    }

    return (
        <div style={visu? { pointerEvents: "none"} : {}}>
            <Grid container justify="space-between" style={{ marginBottom: 100 }}>
                <Grid item xs={3}>
                    <ParametreSelect
                        options={listeTitres}
                        placeholder="Titre"
                        value={_.get(allValues, "paramTitre.idParametre", "")}
                        onChange={(selected) => {
                            setAllValues({
                                ...allValues,
                                paramTitre: {
                                    idParametre: selected.idParametre
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <AppInput
                        label="Prénom"
                        value={allValues.prenom}
                        onChange={(ev) => {
                            setAllValues({
                                ...allValues,
                                prenom: ev.target.value
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <AppInput
                        label="Nom"
                        value={allValues.nom}
                        onChange={(ev) => {
                            setAllValues({
                                ...allValues,
                                nom: ev.target.value
                            })
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container justify="space-between" style={{ marginBottom: 100 }}>
                <Grid item xs={3}>
                    <ParametreSelect
                        options={listePays}
                        placeholder="Pays"
                        value={_.get(allValues, "paramPays.idParametre", "")}
                        onChange={(selected) => {
                            setAllValues({
                                ...allValues,
                                paramPays: {
                                    idParametre: selected.idParametre
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <AppDatePicker
                        label="Date d'anniversaire"
                        value={allValues.dateAnniversaire}
                        onChange={(date) => {
                            setAllValues({
                                ...allValues,
                                dateAnniversaire: date
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <AppDatePicker
                        label="Dernièrement vue"
                        value={allValues.dernierementVue}
                        onChange={(date) => {
                            setAllValues({
                                ...allValues,
                                dernierementVue: date
                            })
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container justify="space-around"  style={{ marginBottom: 100 }}>
                <Grid item xs={4}>
                    <AppInput
                        label="Numéro de téléphone"
                        value={allValues.numeroTelephone}
                        onChange={(ev) => {
                            setAllValues({
                                ...allValues,
                                numeroTelephone: ev.target.value
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AppInput
                        label="Adresse électronique"
                        value={allValues.mail}
                        onChange={(ev) => {
                            setAllValues({
                                ...allValues,
                                mail: ev.target.value
                            })
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container justify="space-between" style={{ marginBottom: 100 }}>
                <Grid item xs={3}>
                    <AppInput
                        label="NPA"
                        value={allValues.npa}
                        onChange={(ev) => {
                            setAllValues({
                                ...allValues,
                                npa: ev.target.value
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <AppInput
                        label="Ville"
                        value={allValues.ville}
                        onChange={(ev) => {
                            setAllValues({
                                ...allValues,
                                ville: ev.target.value
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <AppInput
                        label="Rue"
                        value={allValues.rue}
                        onChange={(ev) => {
                            setAllValues({
                                ...allValues,
                                rue: ev.target.value
                            })
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container justify="space-around"  style={{ marginBottom: 100 }}>
            <Grid item xs={4}>
                <ParametreSelect
                    options={listeTypeLogement}
                    placeholder="Logement"
                    value={_.get(allValues, "paramTypeLogement.idParametre", "")}
                    onChange={(selected) => {
                        setAllValues({
                            ...allValues,
                            paramTypeLogement: {
                                idParametre: selected.idParametre
                            }
                        })
                    }}
                />
            </Grid>
                <Grid item xs={4}>
                    <ParametreSelect
                        options={listeEtatsCivils}
                        placeholder="État Civil"
                        value={_.get(allValues, "paramEtatCivil.idParametre", "")}
                        onChange={(selected) => {
                            setAllValues({
                                ...allValues,
                                paramEtatCivil: {
                                    idParametre: selected.idParametre
                                }
                            })
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container justify="space-between" style={{ marginBottom: 100 }}>
                <Grid item xs={5} container alignItems="center">
                    <Grid item xs={5} style={{textAlign: "center"}}>
                        <span>Mandat de gestion</span>
                    </Grid>
                    <Grid item xs={7}>
                        <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            value={allValues.mandatDeGestion? "Oui" : "Non"}
                            onChange={() => {
                                setAllValues({
                                    ...allValues,
                                    mandatDeGestion: !allValues.mandatDeGestion
                                });
                            }}
                        >
                        <FormControlLabel
                            value="Non"
                            control={<Radio color="primary" />}
                            label="Non"
                        />
                        <FormControlLabel
                            value="Oui"
                            control={<Radio color="primary" />}
                            label="Oui"
                        />
                        </RadioGroup>
                    </Grid>
                </Grid>
                <Grid item xs={5} container alignItems="center">
                    <Grid item xs={5} style={{textAlign: "center"}}>
                        <span>Prospect</span>
                    </Grid>
                    <Grid item xs={7}>
                        <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            value={allValues.prospect? "Oui" : "Non"}
                            onChange={() => {
                                setAllValues({
                                    ...allValues,
                                    prospect: !allValues.prospect
                                });
                            }}
                        >
                        <FormControlLabel
                            value="Non"
                            control={<Radio color="primary" />}
                            label="Non"
                        />
                        <FormControlLabel
                            value="Oui"
                            control={<Radio color="primary" />}
                            label="Oui"
                        />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="center" style={{ marginBottom: 100 }}>
                <Grid item xs={6}>
                    <AppInput
                        label="IBAN"
                        value={allValues.iban}
                        onChange={(ev) => {
                            setAllValues({
                                ...allValues,
                                iban: ev.target.value
                            })
                        }}
                    />
                </Grid>
            </Grid>
            {!visu && (
                <Grid container justify="flex-end">
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ textTransform: "none" }}
                            onClick={save}
                            disabled={isLoading || !isValidForm()}
                        >
                            {isLoading?
                                <CircularProgress disableShrink size={24} />
                                :
                                "Sauvegarder"
                            }
                        </Button>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}
