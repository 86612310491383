import React from "react";
import { 
    Button
} from "@material-ui/core";

export default function PageNotFoundError({ history })
{

	return (
        <div style={{ padding: 15 }}>
            <div>
                <h2>404</h2>
                <h3>Page non trouvée !</h3>
                <p>Désolé, le contenu que vous recherchez est introuvable.</p>
                <Button color="primary" onClick={() => {history.goBack()}} style={{textTransform: 'none'}}>
                    &larr; Retour
                </Button>
            </div>
        </div>
    )
}
