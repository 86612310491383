import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable, { MTableToolbar } from 'material-table'
import _ from "lodash";
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import { Grid, Icon, Tooltip, Typography } from '@material-ui/core';
import { getColumnTitle } from '../../../functions/TableFunctions';
import { postRequest } from '../../../functions/MakeApiCall';
import * as ACTIONS from "../../../store/actions/actions";
import { ResponseMessage } from '../../../functions/MessageFunctions';
import TableLoading from '../../../components/TableLoading/TableLoading';
import AppInput from '../../../components/AppInput/AppInput';

const useStyles = makeStyles((theme) => ({
    formGroup: {
        position: "relative",
        border: "1px solid " + theme.palette.divider,
        borderRadius: 2,
        padding: "12px 12px 0 12px",
    },
    formGroupTitle: {
        position: "absolute",
        top: -10,
        left: 8,
        fontWeight: 600,
        padding: "0 4px",
        backgroundColor: theme.palette.background.paper,
    }
}));

const tableColumns = [  
    {
        title: getColumnTitle("Demande"),
        field: "demande",
        editComponent: (props) => (
            <AppInput
                value={_.get(props.rowData, 'demande', '')}
                onChange={(ev) => {
                    props.onChange(ev.target.value)
                }}
            />
        )
    }
];

export default function DemandeModification() {
    
    const classes = useStyles();

    const addButtonRef = useRef();

    const dispatch = useDispatch();

    const token = useSelector(state => state.utilisateur.token);
    const clientObj = useSelector(state => state.client.obj);

    return (
        <div>
            <div className={clsx(classes.formGroup, "px-12 py-12")}>
                <Typography
                    className={classes.formGroupTitle}
                    color="textSecondary"
                >
                    <span style={{ display: "flex" }}>
                        <span style={{ paddingLeft: 4, display: "flex" }}>
                            <Tooltip title="Ajouter" placement="top-start">
                                <Icon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        addButtonRef.current.click();
                                    }}
                                >
                                        add_box
                                </Icon>   
                            </Tooltip>
                        </span>
                    </span>
                </Typography>
                <MaterialTable
                    options={{
                        actionsColumnIndex: tableColumns.length,
                        addRowPosition: "first",
                        draggable: false
                    }}
                    components={{
                        OverlayLoading: TableLoading,
                        Toolbar: (props) => (
                            <div style={{ display: "none", height: "0" }}>
                                <MTableToolbar {...props} />
                            </div>
                        )
                    }}
                    columns={tableColumns}
                    data={[ ...clientObj.setDemandeModification || [] ].sort((a, b) => b.idDemandeModification - a.idDemandeModification)}
                    icons={{
                        Add: (props) => <div ref={addButtonRef} />,
                    }}
                    editable={{
                        onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    if(!newData.demande)
                                    {
                                        dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Fields obligatoires manquants !", "error")));
                                        return reject();
                                    }

                                    let newTableData = [ ..._.get(clientObj, 'setDemandeModification', []) || [] ];
                                    newTableData.push(newData);

                                    let body = {
                                        ...clientObj,
                                        setDemandeModification: [ ...newTableData ]
                                    };
                                    
                                    postRequest('client/saveClientDemande', body, token).then((response) => {
                                        dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                        dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Demande créée avec succès.", "success")));
                                        resolve();
                                    }).catch((err) => {
                                        let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                        dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                        return reject();
                                    })
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    if(!newData.demande)
                                    {
                                        dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Fields obligatoires manquants !", "error")));
                                        return reject();
                                    }

                                    let newTableData = [ ..._.get(clientObj, 'setDemandeModification', []) || [] ];
                                    newTableData[newTableData.indexOf(oldData)] = newData;

                                    let body = {
                                        ...clientObj,
                                        setDemandeModification: [ ...newTableData ]
                                    };
                                    
                                    postRequest('client/saveClientDemande', body, token).then((response) => {
                                        dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                        dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Demande modifiée avec succès.", "success")));
                                        resolve();
                                    }).catch((err) => {
                                        let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                        dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                        return reject();
                                    })
                                }, 600);
                            }),
                        onRowDelete: (oldData) => 
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    let newTableData = [ ..._.get(clientObj, 'setDemandeModification', []) || [] ];
                                    newTableData.splice(newTableData.indexOf(oldData), 1);
                                    
                                    let body = {
                                        ...clientObj,
                                        setDemandeModification: [ ...newTableData ]
                                    };
                                    
                                    postRequest('client/saveClientDemande', body, token).then((response) => {
                                        dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                        dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Demande supprimée avec succès.", "success")));
                                        resolve();
                                    }).catch((err) => {
                                        let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                        dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                        return reject();
                                    })
                                }, 600);
                            })
                    }}
                    localization={{
                        header: {
                            actions: ""
                        },
                        body: {
                            emptyDataSourceMessage: "Pas de demande !",
                            editRow: {
                                deleteText: "Êtes-vous sure de vouloir supprimer cette demande ?",
                            },
                            deleteTooltip: "Supprimer",
                            editTooltip: "Modifier"
                        },
                    }}
                />
            </div>
            <Grid container justify="center" style={{ marginTop: 40 }}>
                <Grid item>
                    <Typography variant="h4">
                        Vous pouvez me contacter directement sur&nbsp;
                        <a href="https://sergeheberling.ch/" target="_blank" rel="noopener noreferrer">mon site web</a>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}
