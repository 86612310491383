export function formatFrToEng(frDate, sep = "/", newSep = "/") {
    if (!frDate) return "";

    let parts = frDate.split(sep);
    if(parts.length !== 3) return "";
    
    let day = parts[0];
    let month = parts[1];
    let year = parts[2];
    
    let engDate = month + newSep + day + newSep + year;
    return engDate;
}

export function formatEngToFr(engDate, sep = "/", newSep = "/") {
    if (!engDate) return "";

    let parts = engDate.split(sep);
    if(parts.length !== 3) return "";
    
    let day = parts[0];
    let month = parts[1];
    let year = parts[2];
    
    let frDate = day + newSep + month + newSep + year;
    return frDate;
}

export function formatDateToFr(date, sep = "/", noYear = false) {
    if (!date || Object.prototype.toString.call(date) !== "[object Date]") return "";

    let year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    if(noYear) return day + sep + month;
    return day + sep + month + sep + year;
}

export function formatFrToDate(frDate, sep = "/") {
    let engDate = formatFrToEng(frDate, sep);
    if(!engDate) return null;
    let date = new Date(engDate);
    return date;
}

export function formatEngToDate(engDate) {
    let date = new Date(engDate);
    return date;
}

export function formatDateToEng(date, sep = "/") {
    if (!date || Object.prototype.toString.call(date) !== "[object Date]") return "";

    let year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + sep + day + sep + year;
}