// Client
export const SAVE_UTILISATEUR_TOKEN = "SAVE_UTILISATEUR_TOKEN";
export const SAVE_UTILISATEUR_OBJ = "SAVE_UTILISATEUR_OBJ";
export const SAVE_UTILISATEUR_ALL = "SAVE_UTILISATEUR_ALL";
export const CLEAR_UTILISATEUR = "CLEAR_UTILISATEUR";

// Client
export const SAVE_CLIENT_OBJ = "SAVE_CLIENT_OBJ";
export const SAVE_CLIENT_ALL = "SAVE_CLIENT_ALL";
export const CLEAR_CLIENT = "CLEAR_CLIENT";

// Param
export const SAVE_PARAM_INITIALIZED = "SAVE_PARAM_INITIALIZED";
export const SAVE_PARAM_LISTS = "SAVE_PARAM_LISTS";
export const SAVE_PARAM_ALL = "SAVE_PARAM_ALL";
export const CLEAR_PARAM = "CLEAR_PARAM";

// Message
export const MESSAGE_SHOW = "MESSAGE_SHOW";
export const MESSAGE_HIDE = "MESSAGE_HIDE";

