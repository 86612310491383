import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import TableLoading from '../../components/TableLoading/TableLoading';
import { getRequest, deleteRequest, postRequest } from '../../functions/MakeApiCall';
import _ from "lodash";
import * as ACTIONS from "../../store/actions/actions";
import { ResponseMessage } from '../../functions/MessageFunctions';
import MaterialTable, { MTableToolbar } from 'material-table';
import { getColumnTitle } from '../../functions/TableFunctions';
import {
    Typography,
    Icon,
    Tooltip,
    Button,
    Grid
} from '@material-ui/core';
import AppInput from '../../components/AppInput/AppInput';
import { getListeParametre, getValueFromList } from '../../functions/ParamFunctions';
import ParametreSelect from '../../components/ParametreSelect/ParametreSelect';
import Select from 'react-select';
import clsx from "clsx";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formGroup: {
        position: "relative",
        border: "1px solid " + theme.palette.divider,
        borderRadius: 2,
        padding: "12px 12px 0 12px",
    },
    formGroupTitle: {
        position: "absolute",
        top: -10,
        left: 8,
        fontWeight: 600,
        padding: "0 4px",
        backgroundColor: theme.palette.background.default
    }
}));

export default function Utilisateurs() {

    const classes = useStyles();

    const addButtonRef = useRef();

    const dispatch = useDispatch();
    
    const token = useSelector(state => state.utilisateur.token);
    const utilisateurObj = useSelector(state => state.utilisateur.obj);

    const {
        listeTypesUtilisateur
    } = useSelector(state => state.param.lists)

    const [isLoading, setIsLoading] = useState(false);
    const [allUtilisateurs, setAllUtilisateurs] = useState([]);

    const [tableColumns, setTableColumns] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [changingPassword, setChangingPassword] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getRequest('utilisateur/getAllUtilisateur', token).then((response) => {
            setAllUtilisateurs(response.data);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            let msg = _.get(err.response, 'data.message', 'Erreur interne');
            dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
        })
    }, [token, dispatch])

    useEffect(() => {
        getListeParametre([
            "Types Utilisateur"
        ])
        return () => {
            dispatch(ACTIONS.SAVE_PARAM("CLEAR"));
        }
    }, [dispatch]);

    useEffect(() => {
        setTableColumns([
            {
                title: getColumnTitle("Login"),
                field: "login",
                editComponent: (props) => (
                    <AppInput
                        value={_.get(props.rowData, "login", "")}
                        onChange={(ev) => {
                            props.onChange(ev.target.value)
                        }}
                    />
                ),
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            },
            {
                title: getColumnTitle("Mot de passe"),
                field: "password",
                render: (rowData) => (
                    <span>******</span>
                ),
                editComponent: (props) => (
                    <>
                        {_.get(props.rowData, "idUtilisateur", "")?
                            <>
                                <Grid container alignItems="flex-end">
                                    <AppInput
                                        disabled={!changingPassword}
                                        value={changingPassword? _.get(props.rowData, "password", "") : "123456"}
                                        type={changingPassword? "text" : "password"}
                                        onChange={(ev) => {
                                            props.onChange(ev.target.value)
                                        }}
                                    />
                                    <Button style={{textTransform: "none", fontSize: 11}} disabled={changingPassword} onClick={() => {
                                        setChangingPassword(true);
                                        props.onChange("");
                                    }}>
                                        Modifier mot de passe
                                    </Button>
                                </Grid>
                            </>
                            :
                            <AppInput
                                value={_.get(props.rowData, "password", "")}
                                onChange={(ev) => {
                                    props.onChange(ev.target.value)
                                }}
                            />
                        }
                    </>
                ),
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            },
            {
                title: getColumnTitle("Type Utilisateur"),
                field: "paramTypeUtilisateur.idParametre",
                render: (rowData) => (
                    <span>{getValueFromList(listeTypesUtilisateur, _.get(rowData, 'paramTypeUtilisateur.idParametre', ''))}</span>
                ),
                editComponent: (props) => (
                    <ParametreSelect
                        value={_.get(props.rowData, 'paramTypeUtilisateur.idParametre', '')}
                        options={listeTypesUtilisateur}
                        onChange={(selected) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                paramTypeUtilisateur: {
                                    idParametre: selected.idParametre
                                }
                            })
                        }}
                    />
                ),
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            },
            {
                title: getColumnTitle("Client associé"),
                field: "objClient",
                render: (rowData) => (
                    <span>{getValueFromList(clientOptions, _.get(rowData, 'objClient.idClient', ''), false)}</span>
                ),
                editComponent: (props) => (
                    <Select
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        options={clientOptions}
                        value={clientOptions.find(option => option.value === _.get(props.rowData, 'objClient.idClient', '')) || null}
                        onChange={(selected) => {
                            props.onChange({
                                idClient: selected.value
                            });
                        }}
                    />
                ),
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            }
        ])
    }, [listeTypesUtilisateur, clientOptions, changingPassword])

    useEffect(() => {
        getRequest('client/getAllClient', token).then((response) => {
            let returnedClients = response.data;
            let newClientOptions = [];
            returnedClients.forEach((client) => {
                newClientOptions.push({
                    label: getClientLabel(client),
                    value: client.idClient,
                    clientObj: { ...client }
                })
            })
            setClientOptions(newClientOptions);
        })
    }, [token])

    function getClientLabel(client) {
        let allLabel = [];

        let title = _.get(client, "paramTitre.valeur", "");
        if(title) allLabel.push(title);
        if(client.prenom) allLabel.push(client.prenom);
        if(client.nom) allLabel.push(client.nom);

        return allLabel.join(" ");
    }

    function validUtilisateur(obj) {
        return obj.login && obj.password;
    }

    return (
        <div style={{ padding: 16 }}>
            <div className={clsx(classes.formGroup, "px-12 py-12")}>
                <Typography
                    className={classes.formGroupTitle}
                    color="textSecondary"
                >
                    <span style={{ display: "flex" }}>
                        <span style={{ paddingLeft: 4, display: "flex" }}>
                            Utilisateurs
                            <Tooltip title="Ajouter" placement="top-start">
                                <Icon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        addButtonRef.current.click();
                                    }}
                                >
                                        add_box
                                </Icon>   
                            </Tooltip>
                        </span>
                    </span>
                </Typography>
                <MaterialTable
                    data={allUtilisateurs.sort((a, b) => b.idUtilisateur - a.idUtilisateur)}
                    columns={tableColumns}
                    options={{
                        actionsColumnIndex: tableColumns.length,
                        addRowPosition: "first",
                        draggable: false
                    }}
                    components={{
                        OverlayLoading: TableLoading,
                        Toolbar: (props) => (
                            <div style={{ display: "none", height: "0" }}>
                                <MTableToolbar {...props} />
                            </div>
                        )
                    }}
                    icons={{
                        Add: (props) => <div ref={addButtonRef} />,
                    }}
                    localization={{
                        header: {
                            actions: ""
                        },
                        body: {
                            emptyDataSourceMessage: "Pas d'utilisateur !",
                            editRow: {
                                deleteText:
                                    "Êtes-vous sure de vouloir supprimer cet utilisateur?",
                            },
                            deleteTooltip: "Supprimer"
                        }
                    }}
                    editable={{
                        isDeletable: (rowData) => _.get(rowData, "idUtilisateur", "") !== _.get(utilisateurObj, "idUtilisateur", ""),
                        onRowAdd: (newData) => new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if(!validUtilisateur(newData)) {
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Fields obligatoires manquants !", "error")));
                                    return reject();
                                }
                                setChangingPassword(false);

                                let newAllUtilisateurs = [ ...allUtilisateurs ];
                                postRequest('utilisateur/saveUtilisateur', newData, token).then((response) => {
                                    newAllUtilisateurs.push({ ...response.data });
                                    setAllUtilisateurs(newAllUtilisateurs);
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Utilisateur " + newData.login + " créé avec succès.", "success")));
                                    resolve();
                                }).catch((err) => {
                                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                    reject();
                                })
                            }, 600);
                        }),
                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if(!validUtilisateur(newData)) {
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Fields obligatoires manquants !", "error")));
                                    return reject();
                                }
                                setChangingPassword(false);

                                let newAllUtilisateurs = [ ...allUtilisateurs ];
                                postRequest('utilisateur/saveUtilisateur', newData, token).then((response) => {
                                    let newUtilisateur;
                                    if(_.get(newData, "idUtilisateur", "") === _.get(utilisateurObj, "idUtilisateur", "")) {
                                        newUtilisateur = { ...response.data.utilisateur };
                                        let newToken = _.get(response.data, "token", ""); 
                                        dispatch(ACTIONS.SAVE_UTILISATEUR("ALL", {
                                            token: newToken,
                                            obj: newUtilisateur
                                        }));
                                    } else {
                                        newUtilisateur = { ...response.data };
                                    }
                                    newAllUtilisateurs[newAllUtilisateurs.indexOf(oldData)] = newUtilisateur;
                                    setAllUtilisateurs(newAllUtilisateurs);
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Utilisateur " + newData.login + " modifié avec succès.", "success")));
                                    resolve();
                                }).catch((err) => {
                                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                    reject();
                                })
                            }, 600);
                        }),
                        onRowDelete: (oldData) => new Promise((resolve, reject) => {
                            setTimeout(() => {
                                let newAllUtilisateurs = [ ...allUtilisateurs ];
                                newAllUtilisateurs.splice(newAllUtilisateurs.indexOf(oldData), 1);

                                deleteRequest('utilisateur/deleteUtilisateur/' + oldData.idUtilisateur, token).then(() => {
                                    setAllUtilisateurs(newAllUtilisateurs);
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Suppression de l'utilisateur " + oldData.login + " réussie.", "success")));
                                    resolve();
                                }).catch((err) => {
                                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                    reject();
                                })
                            }, 600);
                        })
                    }}
                    isLoading={isLoading}
                />
            </div>
        </div>
    )
}
