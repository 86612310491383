import React , { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
    AppBar,
    Tabs,
    Tab,
    Box 
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    InformationGenerale,
    RelationPersonelle,
    RemarqueOffreConseil,
    EngagementFinancier,
    Impot,
    Assurance,
    ContratTiers,
    VieIndividuelle,
    DemandeModification
} from "../Tabs";
import { useDispatch, useSelector } from 'react-redux';
import * as ACTIONS from "../../store/actions/actions"
import { getListeParametre } from '../../functions/ParamFunctions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            style={{height: 500}}
            {...other}
        >
            {value === index && (
                <Box p={5}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    labelTab: {
        textTransform: "none"
    }
}));

export default function AjouterClient({ history, ...otherProps }) {

    const dispatch = useDispatch();

    const classes = useStyles();

    const theme = useTheme();

    const clientObj = useSelector(state => state.client.obj);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    useEffect(() => {
        getListeParametre([
            "Pays",
            "Titres",
            "Etats Civils",
            "Type Logement",
            "Types Remarque",
            "Types Relation"
        ])
        return () => {
            dispatch(ACTIONS.SAVE_CLIENT("CLEAR"));
            dispatch(ACTIONS.SAVE_PARAM("CLEAR"));
        }
    }, [dispatch]);

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                >
                    <Tab label="Information Générale" {...a11yProps(0)} className={classes.labelTab}/>
                    <Tab label="Relation Personelle" {...a11yProps(1)} className={classes.labelTab} disabled={!clientObj.idClient}/>
                    <Tab label="Remarque Offre/Conseil" {...a11yProps(2)} className={classes.labelTab} disabled={!clientObj.idClient}/>
                    <Tab label="Vie Individuelle" {...a11yProps(3)} className={classes.labelTab} disabled={!clientObj.idClient}/>
                    <Tab label="Engagement Financier" {...a11yProps(4)} className={classes.labelTab} disabled={!clientObj.idClient}/>
                    <Tab label="Impôt" {...a11yProps(5)} className={classes.labelTab} disabled={!clientObj.idClient}/>
                    <Tab label="Assurance" {...a11yProps(6)} className={classes.labelTab} disabled={!clientObj.idClient}/>
                    <Tab label="Contrat Tiers" {...a11yProps(7)} className={classes.labelTab} disabled={!clientObj.idClient}/>
                    <Tab label="Demande Modification" {...a11yProps(8)} className={classes.labelTab} disabled={!clientObj.idClient}/>
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <InformationGenerale />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <RelationPersonelle history={history} changeTabValue={handleChangeIndex}/>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <RemarqueOffreConseil />
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <VieIndividuelle />
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction}>
                    <EngagementFinancier />
                </TabPanel>
                <TabPanel value={value} index={5} dir={theme.direction}>
                    <Impot />
                </TabPanel>
                <TabPanel value={value} index={6} dir={theme.direction}>
                    <Assurance />
                </TabPanel>
                <TabPanel value={value} index={7} dir={theme.direction}>
                    <ContratTiers />
                </TabPanel>
                <TabPanel value={value} index={8} dir={theme.direction}>
                    <DemandeModification />
                </TabPanel>
            </SwipeableViews>
        </div>
    );
}