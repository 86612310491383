import UtilisateurReducer from './UtilisateurReducer';
import ClientReducer from './ClientReducer';
import ParamReducer from './ParamReducer';
import MessageReducer from './MessageReducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
	utilisateur: UtilisateurReducer,
	client: ClientReducer,
	param: ParamReducer,
	message: MessageReducer
});

export default rootReducer;