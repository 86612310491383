import React from "react";
import { Tooltip } from "@material-ui/core";

export function getColumnTitle(title, tooltip) {
    if(tooltip)
    {
        return (
            <Tooltip title={tooltip} enterDelay={300} placement={"top-start"}>
                <div style={{width: "max-content", margin: "auto"}}>{title}</div>
            </Tooltip>
        )
    } else {
        return (
            <div style={{width: "max-content", margin: "auto"}}>{title}</div>
        )
    }
}

export function isEmptyRow(rowData) {
    return Object.keys(rowData).some((key) => !rowData[key]) || Object.keys(rowData).length === 0;
}