import React from 'react'
import Select, { components } from 'react-select'

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

export default function ParametreSelect({error, value, options, placeholder, ...otherProps}) {
    if(placeholder) {
      return (
        <Select
            components={{
                ValueContainer: CustomValueContainer
            }}
            styles={{
                // Fixes the overlapping problem of the component
                menu: provided => ({ ...provided, zIndex: 9999 }),

                // Add label on top even when value is selected
                valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible"
                }),
                placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top: state.hasValue || state.selectProps.inputValue ? -3 : "50%",
                    backgroundColor: "white",
                    paddingLeft: "5px",
                    paddingRight:"5px",
                    transition: "top 0.1s, font-size 0.1s",
                    fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                })
            }}
            placeholder={placeholder}
            options={options || []}
            value={(options || []).find(option => option.idParametre === value) || null}
            theme={theme => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    neutral20: error ? 'red' : 'lightGray',
                },
            })}
            getOptionLabel={option => option.valeur}
            getOptionValue={option => option.idParametre}
            {...otherProps}
        />
    );
  } else {
    return (
      <Select
          styles={{
              // Fixes the overlapping problem of the component
              menu: provided => ({ ...provided, zIndex: 9999 })
          }}
          options={options || []}
          value={(options || []).find(option => option.idParametre === value) || null}
          theme={theme => ({
              ...theme,
              colors: {
                  ...theme.colors,
                  neutral20: error ? 'red' : 'lightGray',
              },
          })}
          getOptionLabel={option => option.valeur}
          getOptionValue={option => option.idParametre}
          {...otherProps}
      />
  );
  }
}