import React from "react";
import {Redirect} from "react-router-dom";
// Layout Types
import EmptyLayout from "./layouts/EmptyLayout/EmptyLayout";
import DefaultLayout from "./layouts/DefaultLayout/DefaultLayout";
import AuthLayout from "./layouts/AuthLayout/AuthLayout";
// Route Views
import Signup from "./views/Auth/Signup";
import Login from "./views/Auth/Login";
import PageNotFoundError from "./views/Errors/PageNotFoundError";
import RechercheClient from "./views/RechercheClient/RechercheClient";
import AjouterClient from "./views/AjouterClient/AjouterClient";
import VisuClient from "./views/VisuClient/VisuClient";
import Notifications from "./views/Notifications/Notifications";
import Utilisateurs from "./views/Utilisateurs/Utilisateurs";
import MoteurDeRecherche from "./views/MoteurDeRecherche/MoteurDeRecherche";


export default [
    {
        path: "/",
        exact: true,
        layout: DefaultLayout,
        component: () => <Redirect to="/Notifications"/>
    },
    {
        path: "/signup",
        exact: true,
        layout: AuthLayout,
        component: Signup,
        login: false,
        admin: false
    },
    {
        path: "/login",
        exact: true,
        layout: AuthLayout,
        component: Login,
        login: false,
        admin: false
    },
    {
        path: "/RechercheClient",
        exact: true,
        layout: DefaultLayout,
        component: RechercheClient,
        login: true,
        admin: true
    },
    {
        path: "/AjouterClient",
        exact: true,
        layout: DefaultLayout,
        component: AjouterClient,
        login: true,
        admin: true
    },
    {
        path: "/VisuClient",
        exact: true,
        layout: DefaultLayout,
        component: VisuClient,
        login: true,
        admin: false
    },
    {
        path: "/Notifications",
        exact: true,
        layout: DefaultLayout,
        component: Notifications,
        login: true,
        admin: true
    },
    {
        path: "/Utilisateurs",
        exact: true,
        layout: DefaultLayout,
        component: Utilisateurs,
        login: true,
        admin: true
    },
    {
        path: "/MoteurDeRecherche",
        exact: true,
        layout: DefaultLayout,
        component: MoteurDeRecherche,
        login: true,
        admin: true
    },
    {
        path: "/page-non-trouvee",
        exact: true,
        layout: EmptyLayout,
        component: PageNotFoundError,
        login: false,
        admin: false
    },
    {
        path: "*",
        layout: EmptyLayout,
        component: () => <Redirect to="/page-non-trouvee"/>
    }
];
