import React, { useState } from 'react';
import {
    CssBaseline,
    Drawer,
    AppBar,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
    Icon,
    ListItem,
    ListItemIcon,
    ListItemText,
    Button,
    MenuItem,
    Popover
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import * as ACTIONS from "../../store/actions/actions";
import {Link} from 'react-router-dom';
import { isAdmin } from '../../functions/UtilisateurFunctions';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    toolbar: {
        paddingRight: 24
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        textAlign: "center"
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: 0,
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));

export default function DefaultLayout({ children, history, ...otherProps }) {

    const dispatch = useDispatch();
    
    const classes = useStyles();

    const clientObj = useSelector(state => state.client.obj);
    const utilisateurObj = useSelector(state => state.utilisateur.obj);

    const admin = isAdmin(utilisateurObj);
    
    const [open, setOpen] = useState(true && admin);
    const [userMenu, setUserMenu] = useState(null);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const userMenuClick = event => {
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    function getClientLabel() {
        if(!window.location.href.includes("AjouterClient")) return "";
        let allLabel = [];

        let title = _.get(clientObj, "paramTitre.valeur", "");
        if(title) allLabel.push(title);
        if(clientObj.prenom) allLabel.push(clientObj.prenom);
        if(clientObj.nom) allLabel.push(clientObj.nom);

        return allLabel.join(" ");
    }

    function logout() {
        dispatch(ACTIONS.SAVE_UTILISATEUR("CLEAR"));
        history.push("/login");
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    {admin && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <Icon> menu </Icon>
                        </IconButton>
                    )}
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {getClientLabel()}
                    </Typography>
                    <Button className="h-64" onClick={userMenuClick}>
                        <Icon style={{marginRight: "5px", color: "white"}}>
                            person
                        </Icon>
                        <div className="hidden md:flex flex-col ml-12 items-start">
                            <Typography component="span" className="normal-case font-600 flex" style={{textTransform: 'none', color: "white"}}>
                                {_.get(utilisateurObj, "login", "")}
                            </Typography>
                        </div>
                    </Button>
                    <Popover
                        open={Boolean(userMenu)}
                        anchorEl={userMenu}
                        onClose={userMenuClose}
                        anchorOrigin={{
                            vertical  : 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical  : 'top',
                            horizontal: 'center'
                        }}
                    >
                        <MenuItem
                            component={Link}
                            to="/login"
                            onClick={logout}
                        >
                            <ListItemText primary="Déconnexion"/>
                        </MenuItem>
                    </Popover>
                </Toolbar>
            </AppBar>
            {admin && (
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={handleDrawerClose}>
                            <Icon> chevron_left </Icon>
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <ListItem button onClick={() => history.push("/RechercheClient")}>
                            <ListItemIcon>
                                <Icon> people </Icon>
                            </ListItemIcon>
                            <ListItemText primary="Recherche Client" />
                        </ListItem>
                        <ListItem button onClick={() => history.push("/AjouterClient")}>
                            <ListItemIcon>
                                <Icon> add </Icon>
                            </ListItemIcon>
                            <ListItemText primary="Ajouter Client" />
                        </ListItem>
                        <ListItem button onClick={() => history.push("/Utilisateurs")}>
                            <ListItemIcon>
                                <Icon> person </Icon>
                            </ListItemIcon>
                            <ListItemText primary="Utilisateurs" />
                        </ListItem>
                        <ListItem button onClick={() => history.push("/Notifications")}>
                            <ListItemIcon>
                                <Icon> notifications </Icon>
                            </ListItemIcon>
                            <ListItemText primary="Notifications" />
                        </ListItem>
                        <ListItem button onClick={() => history.push("/MoteurDeRecherche")}>
                            <ListItemIcon>
                                <Icon> settings </Icon>
                            </ListItemIcon>
                            <ListItemText primary="Moteur De Recherche" />
                        </ListItem>
                    </List>
                </Drawer>
            )}
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                {children}
            </main>
        </div>
    );
}
