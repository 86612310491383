import * as ACTION_TYPES from './action_types';

export const SAVE_UTILISATEUR = (type = 'ALL', object) => {
    switch(type)
    {
        case 'TOKEN':
            return {
                type: ACTION_TYPES.SAVE_UTILISATEUR_TOKEN,
                payload: object
            };
        case 'OBJ':
            return {
                type: ACTION_TYPES.SAVE_UTILISATEUR_OBJ,
                payload: object
            };
        case 'ALL':
            return {
                type: ACTION_TYPES.SAVE_UTILISATEUR_ALL,
                payload: object
            };
        case 'CLEAR':
            return {
                type: ACTION_TYPES.CLEAR_UTILISATEUR
            };
        default:
            return;

    }
};

export const SAVE_CLIENT = (type = 'ALL', object) => {
    switch(type)
    {
        case 'OBJ':
            return {
                type: ACTION_TYPES.SAVE_CLIENT_OBJ,
                payload: object
            };
        case 'ALL':
            return {
                type: ACTION_TYPES.SAVE_CLIENT_ALL,
                payload: object
            };
        case 'CLEAR':
            return {
                type: ACTION_TYPES.CLEAR_CLIENT
            };
        default:
            return;

    }
};

export const SAVE_PARAM = (type = 'ALL', object) => {
    switch(type)
    {
        case 'INITIALIZED':
            return {
                type: ACTION_TYPES.SAVE_PARAM_INITIALIZED,
                payload: object
            };
        case 'LISTS':
            return {
                type: ACTION_TYPES.SAVE_PARAM_LISTS,
                payload: object
            };
        case 'ALL':
            return {
                type: ACTION_TYPES.SAVE_PARAM_ALL,
                payload: object
            };
        case 'CLEAR':
            return {
                type: ACTION_TYPES.CLEAR_PARAM
            };
        default:
            return;
    }
};

export function MESSAGE(type = 'HIDE', options = {})
{
	switch(type) {
		case 'SHOW':
			return {
				type: ACTION_TYPES.MESSAGE_SHOW,
				payload: options
			};
		case 'HIDE':
			return {
				type: ACTION_TYPES.MESSAGE_HIDE
			};
        default:
            return;
	}
}
