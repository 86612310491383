import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { formatDateToFr, formatFrToDate } from '../../functions/DateFunctions';

const useStyles = makeStyles((theme) => ({
    input: {
        height: 38
    }
}));

export default function AppInput({label, value, onChange, ...otherProps}) {

    const classes = useStyles();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    className: classes.input
                }}
                inputVariant="outlined"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label={label}
                value={formatFrToDate(value)}
                onChange={(date) => {
                    onChange(formatDateToFr(date));
                }}
                style={{margin: "unset"}}
            />
        </MuiPickersUtilsProvider>
    );
}