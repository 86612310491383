import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable, { MTableToolbar } from 'material-table'
import _ from "lodash";
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import { CircularProgress, Icon, Tooltip, Typography, IconButton } from '@material-ui/core';
import { getColumnTitle } from '../../../functions/TableFunctions';
import { getValueFromList } from '../../../functions/ParamFunctions';
import { postRequest } from '../../../functions/MakeApiCall';
import * as ACTIONS from "../../../store/actions/actions";
import { ResponseMessage } from '../../../functions/MessageFunctions';
import ParametreSelect from '../../../components/ParametreSelect/ParametreSelect';
import TableLoading from '../../../components/TableLoading/TableLoading';
import AppInput from '../../../components/AppInput/AppInput';
import { FILE_IP } from '../../../constants/FILE_IP';
import { documentUpload } from '../../../functions/DocumentFunctions';

const useStyles = makeStyles((theme) => ({
    formGroup: {
        position: "relative",
        border: "1px solid " + theme.palette.divider,
        borderRadius: 2,
        padding: "12px 12px 0 12px",
    },
    formGroupTitle: {
        position: "absolute",
        top: -10,
        left: 8,
        fontWeight: 600,
        padding: "0 4px",
        backgroundColor: theme.palette.background.paper,
    }
}));

export default function RelationPersonelle({ visu }) {
    
    const classes = useStyles();

    const inputFileRef = useRef();
    const addButtonRef = useRef();

    const dispatch = useDispatch();

    const {
        listeTypesRemarque
    } = useSelector(state => state.param.lists)

    const token = useSelector(state => state.utilisateur.token);
    const clientObj = useSelector(state => state.client.obj);

    const [uploading, setUploading] = useState(false);
    const [documentPath, setDocumentPath] = useState("");

    const [tableColumns, setTableColumns] = useState([]);

    useEffect(() => {
        setTableColumns([
            {
                title: getColumnTitle("Type Remarque"),
                field: "paramTypeRemarque.idParametre",
                render: (rowData) => (
                    <span>{getValueFromList(listeTypesRemarque, _.get(rowData, 'paramTypeRemarque.idParametre', ''))}</span>
                ),
                editComponent: (props) => (
                    <ParametreSelect
                        value={_.get(props.rowData, 'paramTypeRemarque.idParametre', '')}
                        options={listeTypesRemarque}
                        onChange={(selected) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                paramTypeRemarque: {
                                    idParametre: selected.idParametre
                                }
                            })
                        }}
                    />
                )
            },
            {
                title: getColumnTitle("Remarque"),
                field: "remarque",
                editComponent: (props) => (
                    <AppInput
                        value={_.get(props.rowData, 'remarque', '')}
                        onChange={(ev) => {
                            props.onChange(ev.target.value)
                        }}
                    />
                )
            },
            {
                title: getColumnTitle("Document"),
                field: "objDocument.path",
                render: (rowData) => (
                    <IconButton 
                        onClick={() => {
                            let fullPath = FILE_IP + _.get(rowData, "objDocument.path", "");
                            window.open(fullPath, "_blank");
                        }}
                        disabled={!_.get(rowData, "objDocument.path", "")}
                    >
                        <Icon>
                            visibility
                        </Icon>
                    </IconButton>
                ),
                editComponent: (props) => (
                    uploading? 
                        <CircularProgress size={24} disableShrink />
                        :
                        <IconButton
                            onClick={() => {
                                inputFileRef.current.click();
                            }}
                        >
                            <Icon>
                                {!_.get(props.rowData, "objDocument.path", "") && !documentPath?
                                    "cloud_upload"
                                    :
                                    "insert_drive_file"
                                }
                            </Icon>
                        </IconButton>
                ),
                cellStyle: {
                    minWidth: 200
                },
                headerStyle: {
                    minWidth: 200
                }
            }
        ])
    }, [listeTypesRemarque, uploading, inputFileRef, documentPath])

    function selectDocument(ev)
    {
        ev.preventDefault();
        let allFiles = _.get(ev, "target.files", []);
        if (allFiles.length > 0) {
            setUploading(true);
            documentUpload(ev.target.files[0]).then((response) => {
                setUploading(false);
                setDocumentPath(response.data.path);
                dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Upload du document réussi avec succès.", "success")))
            }).catch((err) => {
                setUploading(false);
                dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Erreur interne !", "error")))
            })
        }
    }

    return (
        <div className={clsx(classes.formGroup, "px-12 py-12")}>
            {!visu && (
                <Typography
                    className={classes.formGroupTitle}
                    color="textSecondary"
                >
                    <span style={{ display: "flex" }}>
                        <span style={{ paddingLeft: 4, display: "flex" }}>
                            <Tooltip title="Ajouter" placement="top-start">
                                <Icon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        addButtonRef.current.click();
                                    }}
                                >
                                        add_box
                                </Icon>   
                            </Tooltip>
                        </span>
                    </span>
                </Typography>
            )}
            <MaterialTable
                options={{
                    actionsColumnIndex: tableColumns.length,
                    addRowPosition: "first",
                    draggable: false
                }}
                components={{
                    OverlayLoading: TableLoading,
                    Toolbar: (props) => (
                        <div style={{ display: "none", height: "0" }}>
                            <MTableToolbar {...props} />
                        </div>
                    )
                }}
                columns={tableColumns}
                data={[ ...clientObj.setRemarqueOffreConseil || [] ].sort((a, b) => b.idRemarqueOffreConseil - a.idRemarqueOffreConseil)}
                icons={{
                    Add: (props) => <div ref={addButtonRef} />,
                }}
                editable={{
                    onRowAdd: visu? undefined : (newData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if(documentPath) {
                                    newData.objDocument = {
                                        ...newData.objDocument,
                                        path: documentPath
                                    }
                                }

                                if(!_.get(newData, "paramTypeRemarque.idParametre", "")
                                    || !newData.remarque)
                                {
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Fields obligatoires manquants !", "error")));
                                    return reject();
                                }
                                setDocumentPath("");

                                let newTableData = [ ..._.get(clientObj, 'setRemarqueOffreConseil', []) || [] ];
                                newTableData.push(newData);

                                let body = {
                                    ...clientObj,
                                    setRemarqueOffreConseil: [ ...newTableData ]
                                };
                                
                                postRequest('client/saveClient', body, token).then((response) => {
                                    dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Remarque créée avec succès.", "success")));
                                    resolve();
                                }).catch((err) => {
                                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                    return reject();
                                })
                            }, 600);
                        }),
                    onRowUpdate: visu? undefined : (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if(documentPath) {
                                    newData.objDocument = {
                                        ...newData.objDocument,
                                        path: documentPath
                                    }
                                }

                                if(!_.get(newData, "paramTypeRemarque.idParametre", "")
                                    || !newData.remarque)
                                {
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Fields obligatoires manquants !", "error")));
                                    return reject();
                                }
                                setDocumentPath("");

                                let newTableData = [ ..._.get(clientObj, 'setRemarqueOffreConseil', []) || [] ];
                                newTableData[newTableData.indexOf(oldData)] = newData;

                                let body = {
                                    ...clientObj,
                                    setRemarqueOffreConseil: [ ...newTableData ]
                                };
                                
                                postRequest('client/saveClient', body, token).then((response) => {
                                    dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Remarque modifiée avec succès.", "success")));
                                    resolve();
                                }).catch((err) => {
                                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                    return reject();
                                })
                            }, 600);
                        }),
                    onRowDelete: visu? undefined : (oldData) => 
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                let newTableData = [ ..._.get(clientObj, 'setRemarqueOffreConseil', []) || [] ];
                                newTableData.splice(newTableData.indexOf(oldData), 1);
                                
                                let body = {
                                    ...clientObj,
                                    setRemarqueOffreConseil: [ ...newTableData ]
                                };
                                
                                postRequest('client/saveClient', body, token).then((response) => {
                                    dispatch(ACTIONS.SAVE_CLIENT("OBJ", { ...response.data }));
                                    dispatch(ACTIONS.MESSAGE("SHOW", ResponseMessage("Remarque supprimée avec succès.", "success")));
                                    resolve();
                                }).catch((err) => {
                                    let msg = _.get(err.response, 'data.message', 'Erreur interne');
                                    dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
                                    return reject();
                                })
                            }, 600);
                        })
                }}
                localization={{
                    header: {
                        actions: ""
                    },
                    body: {
                        emptyDataSourceMessage: "Pas de remarques !",
                        editRow: {
                            deleteText: "Êtes-vous sure de vouloir supprimer cette remarque ?",
                        },
                        deleteTooltip: "Supprimer",
                        editTooltip: "Modifier"
                    },
                }}
            />
            <input
                type="file"
                style={{display: "none"}}
                ref={inputFileRef}
                onChange={selectDocument}
            /> 
        </div>
    )
}
