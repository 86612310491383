import * as ACTION_TYPES from '../actions/action_types';

const initialParamState = {
    initialized: false,
    lists: {}
};

const ParamReducer = (state = initialParamState, action) => {
    switch(action.type) {
        case ACTION_TYPES.SAVE_PARAM_INITIALIZED:
            return {
                ...state,
                initialized: action.payload
            };
        case ACTION_TYPES.SAVE_PARAM_LISTS:
            return {
                ...state,
                lists: action.payload
            };
        case ACTION_TYPES.SAVE_PARAM_ALL:
            return action.payload;
        case ACTION_TYPES.CLEAR_PARAM:
            return initialParamState;
        default:
            return state
    }
};

export default ParamReducer;
