import * as ACTION_TYPES from '../actions/action_types';

const initialMessageState = {
    state  : null,
    options: {
        anchorOrigin    : {
            vertical  : 'bottom',
            horizontal: 'right'
        },
        autoHideDuration: 3000,
        message         : 'Empty',
        variant         : null
    }
};

const MessageReducer = function (state = initialMessageState, action) {
    switch (action.type) {
        case ACTION_TYPES.MESSAGE_SHOW:
            return {
                state  : true,
                options: {
                    ...initialMessageState.options,
                    ...action.payload
                }
            };
        case ACTION_TYPES.MESSAGE_HIDE:
            return {
                ...state,
                state: null
            };
        default:
            return state;
    }
};

export default MessageReducer;
