import React, { useState } from 'react';
import CardPage from './CardPage';
import ClientPage from './ClientPage';

export default function MoteurDeRecherche({ history, ...otherProps}) {

    const [hasChosen, setHasChosen] = useState(false);
    const [endpoint, setEndpoint] = useState(null);

    function handleClick(newEndpoint) {
        setEndpoint(newEndpoint);
        setHasChosen(true);
    }

    function handleBack() {
        setHasChosen(false);
        setEndpoint(null);
    }

    return (
        <div style={{padding: 16}}>
            {!hasChosen?
                <CardPage
                    handleClick={handleClick}
                />
                :
                <ClientPage
                    history={history}
                    endpoint={endpoint}
                    handleBack={handleBack}
                />
            }
        </div>
    )
}
