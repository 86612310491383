import React from 'react'
import { Typography } from '@material-ui/core';
import { getColumnTitle } from '../../functions/TableFunctions';
import TableLoading from '../TableLoading/TableLoading';
import * as ACTIONS from '../../store/actions/actions';
import MaterialTable from 'material-table';
import { useDispatch } from 'react-redux';

const tableColumns = [
    {
        title: getColumnTitle("Titre"),
        field: "paramTitre.valeur"
    },
    {
        title: getColumnTitle("Nom"),
        field: "nom"
    },
    {
        title: getColumnTitle("Prénom"),
        field: "prenom"
    },
    {
        title: getColumnTitle("Prospect"),
        field: "prospect",
        render: (rowData) => (
            <span>{rowData.prospect? "Oui" : "Non"}</span>
        )
    },
    {
        title: getColumnTitle("Pays"),
        field: "paramPays.valeur"
    },
    {
        title: getColumnTitle("Date d'anniversaire"),
        field: "dateAnniversaire"
    },
    {
        title: getColumnTitle("Dernièrement vue"),
        field: "dernierementVue"
    },
    {
        title: getColumnTitle("Numéro de téléphone"),
        field: "numeroTelephone"
    },
    {
        title: getColumnTitle("Adresse électronique"),
        field: "mail"
    }
];

export default function ClientTable({ title = "Clients", clients = [], history, onRowDelete, isLoading}) {
    
    const dispatch = useDispatch();

    return (
        <>
            <MaterialTable
                title={
                    <Typography component="h2" variant="h6" color="primary" gutterBottom>
                        {title}
                    </Typography>
                }
                data={clients.sort((a, b) => b.idClient - a.idClient)}
                columns={tableColumns}
                options={{
                    actionsColumnIndex: tableColumns.length,
                    addRowPosition: "first",
                    draggable: false
                }}
                components={{
                    OverlayLoading: TableLoading
                }}
                localization={{
                    header: {
                        actions: ""
                    },
                    body: {
                        emptyDataSourceMessage: "Pas de client !",
                        editRow: {
                            deleteText:
                                "Êtes-vous sure de vouloir supprimer ce client?",
                        },
                        deleteTooltip: "Supprimer"
                    }
                }}
                editable={{
                    onRowDelete: onRowDelete
                }}
                isLoading={isLoading}
                onRowClick={(event, rowData) => {
                    dispatch(ACTIONS.SAVE_CLIENT("OBJ", rowData));
                    history.push("/AjouterClient");
                }}
            />
        </>
    )
}
