import * as ACTION_TYPES from '../actions/action_types';

const initialClientState = {
    obj: {}
};

const ClientReducer = (state = initialClientState, action) => {
    switch(action.type) {
        case ACTION_TYPES.SAVE_CLIENT_OBJ:
            return {
                ...state,
                obj: action.payload
            };
        case ACTION_TYPES.SAVE_CLIENT_ALL:
            return action.payload;
        case ACTION_TYPES.CLEAR_CLIENT:
            return initialClientState;
        default:
            return state
    }
};

export default ClientReducer;
