import React, { useState } from 'react';
import {
    TextField,
    Grid,
    Divider,
	CircularProgress,
	InputAdornment,
    IconButton,
    Button
} from "@material-ui/core";
import {
	Visibility,
	VisibilityOff
} from "@material-ui/icons";
import { postRequest } from "../../functions/MakeApiCall";
import { ResponseMessage } from "../../functions/MessageFunctions";
import * as ACTIONS from '../../store/actions/actions';
import { useDispatch } from "react-redux";
import _ from 'lodash';
import { isAdmin } from '../../functions/UtilisateurFunctions';
	
function Signup(props)
{
    const dispatch = useDispatch();
    
    const [allValues, setAllValues] = useState({
        login : '',
        password : '',
        password_confirmation : ''
    });
	
    const [showFields, setShowFields] = useState({
        password : false,
        password_confirmation : false
    });

    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let missingFields = {
        login : !allValues.login,
        password : !allValues.password,
        password_confirmation : !allValues.password_confirmation
    };

    let largeFields = {
        login : _.get(allValues, 'login', '').length > 250,
        password : _.get(allValues, 'password', '').length > 250,
        password_confirmation : _.get(allValues, 'password_confirmation', 0).length > 250
    };
	
	let customFields = {
		password_confirmation: allValues.password_confirmation !== allValues.password
	};

    let errorMessages = {
        missingFields : 'Champs obligatoire.',
        largeFields : 'Trop long. (max: 250)',
        customFields : 'Non valide.'
    };

    function handleKeyPress(ev) {
        let validSubmission = checkIfValid();
        if (ev.key === "Enter" && validSubmission)
        {
            signup();
        }
    }
			
	function getErrorStatus(field)
	{
		return submitted && (missingFields[field] || largeFields[field] || customFields[field]);
	}
	
    function getHelperText(field) 
    {
        if(!submitted && (missingFields[field] || largeFields[field] || customFields[field])) return '';
        if(missingFields[field])
        {
            return errorMessages['missingFields'];
        } else if(largeFields[field])
        {
            return errorMessages['largeFields'];
        } else if(customFields[field])
        {
            return errorMessages['customFields'];
        }
    }
	
	function checkIfValid()
	{
		let valid = true;
        valid = !Object.keys(missingFields).some((key) => missingFields[key]);
        if(valid) valid = !Object.keys(largeFields).some((key) => largeFields[key]);
        if(valid) valid = !Object.keys(customFields).some((key) => customFields[key]);
        return valid;
	}
	
	function signup()
	{
		setIsLoading(true);
        setSubmitted(true);
        
		let validSubmission = checkIfValid();
		if(!validSubmission) {
			setIsLoading(false);
			return;
        }
        
        let body = { ...allValues };
        delete body.password_confirmation;

		postRequest('auth/register', body).then((response) => {
            let allData = response.data;
            let token = allData.token;
            let utilisateur = allData.utilisateur;
            dispatch(ACTIONS.SAVE_UTILISATEUR('ALL', {
                token: token,
                obj: utilisateur
            }));
            let newUrl;
            if(isAdmin(utilisateur)) {
                newUrl = "/Notifications";
            } else {
                newUrl = "/VisuClient";
            }
            props.history.push(newUrl);
		})
		.catch((e) => {
            let msg = _.get(e.response, 'data.message', 'Erreur interne');
			dispatch(ACTIONS.MESSAGE('SHOW', ResponseMessage(msg || "Erreur interne", 'error')));
			setIsLoading(false);
			setSubmitted(false);
		});
	}

    return (
        <div style={{padding: 16}}>
            <p style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.6)"
            }}> S'inscrire </p>
            <Divider />
            <Grid
                container
                direction="row"
                justify="space-between"
                spacing={5}
                style={{marginTop: 10}}
            >
                <Grid item xs={12}>
                    <TextField
                        required
                        error={getErrorStatus('login')}
                        helperText={getHelperText('login')}
                        id="outlined-login"
                        label="Login"
                        value={allValues.login}
                        onChange={(ev) => {
                            setAllValues({...allValues,
                                login: ev.target.value
                            });
                        }}
                        onKeyPress={(ev) => handleKeyPress(ev)}
                        variant="outlined"
                        style={{width: '100%'}}
                        inputProps={{ tabIndex: "1" }}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justify="space-between"
                spacing={5}
                style={{marginTop: 10}}
            >
                <Grid item xs={12}>
                    <TextField
                        required
                        error={getErrorStatus('password')}
                        helperText={getHelperText('password')}
						type={showFields.password ? 'text' : 'password'}
                        id="outlined-password"
                        label="Mot de passe"
                        value={allValues.password}
                        onChange={(ev) => {
                            setAllValues({...allValues,
                                password : ev.target.value
                            });
                        }}
                        onKeyPress={(ev) => handleKeyPress(ev)}
						InputProps={{
							endAdornment: 
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => {
											setShowFields({...showFields,
												password : !showFields.password
											});
										}}
										onMouseDown={(ev) => {ev.preventDefault()}}
										edge="end"
									>
										{showFields.password ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
						}}
                        variant="outlined"
                        style={{width: '100%'}}
                        inputProps={{ tabIndex: "2" }}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justify="space-between"
                spacing={5}
                style={{marginTop: 10}}
            >
                <Grid item xs={12}>
                    <TextField
                        required
                        error={getErrorStatus('password_confirmation')}
                        helperText={getHelperText('password_confirmation')}
						type={showFields.password_confirmation ? 'text' : 'password'}
                        id="outlined-password_confirmation"
                        label="Confirmation mot de passe"
                        value={allValues.password_confirmation}
                        onChange={(ev) => {
                            setAllValues({...allValues,
                                password_confirmation : ev.target.value
                            });
                        }}
                        onKeyPress={(ev) => handleKeyPress(ev)}
						InputProps={{
							endAdornment: 
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => {
											setShowFields({...showFields,
												password_confirmation : !showFields.password_confirmation
											});
										}}
										onMouseDown={(ev) => {ev.preventDefault()}}
										edge="end"
									>
										{showFields.password_confirmation ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
						}}
                        variant="outlined"
                        style={{width: '100%'}}
                        inputProps={{ tabIndex: "3" }}
                    />
                </Grid>
            </Grid>
			<div style={{				
				textAlign: 'center',
				marginTop: '30px'
			}}>
				<Button theme="light"
                        variant="contained"
                        disabled={isLoading}
                        onClick={signup}
                        style={{textTransform: 'none', background: 'rgba(0, 0, 0, 0.08)'}}
                >
                    {isLoading?
                        <CircularProgress size={24} disableShrink />
                        :
                        "S'inscrire"
                    }
					
				</Button>
				<div style={{marginTop: '20px', textAlign: '-webkit-right'}}>
                    <Button color="primary" onClick={() => {props.history.push("/login")}} style={{width: 'fit-content', textTransform: 'none'}}>
                        Vous avez déjà un compte? Se connecter
                    </Button>
                </div>
			</div>
        </div>
    );
}

export default Signup;
